import React, { useState } from 'react'
import { Button } from '../../../../../common/components/Authentication/Button'

import discoverPageBackground11 from '../../../assets/discover-page-background-1.5.svg'
import discoverPageBackground2 from '../../../assets/discover-page-background-2.svg'
import discoverPageBackground22 from '../../../assets/discover-page-background-2.5.svg'
import placeholderImage from '../../../assets/image_placeholder.svg'
import cardThumbnail from '../../../assets/card-thumbnail.svg'
import { SiteFooterSocialLinks } from '../../SiteFooterSocialLinks'
import { FeaturedArticle } from './FeaturedArticleSection'
import { PodcastSection } from './PodcastSection'
import { InterviewsSection } from './InterviewSection'
import { LifeThroughLegalLensSection } from './LifeThroughLegalLensSection'
import { DiscoverLegalPerspective } from './LegalPescpectiveSection'
import { SearchAndFilter } from '../../search/SearchAndFilter'
import AudioPlayer from '../../Multimedia/AudioPlayer'
import { useFirebaseCloudStorage } from '../../../common/firebase/useFirebaseCloudStorage'
import { DiscoverMore } from './DiscoverMoreSection'

export const DiscoverLaw = () => {
  const handleSearch = (searchTerm) => {
    console.log('Searching for:', searchTerm)
  }

  const handleFilter = (filterOption) => {
    console.log('Filtering by:', filterOption)
  }

  const handleClearFilters = () => {
    console.log('Clearing filters')
  }

  return (
    <div className='min-h-screen  '>
      <section className=' bg-[#B6CFF1]'>
        <FeaturedArticle />
      </section>
      <main className=' py-8 '>
        <div className='flex justify-between items-center '>
          <div className='bg-black ml-10'>
            <SiteFooterSocialLinks
              isDark
              variant='small'
              className='flex space-x-4 bg-white '
            />
          </div>
          <SearchAndFilter
            onSearch={handleSearch}
            onFilter={handleFilter}
            onClearFilters={handleClearFilters}
          />
        </div>
        <DiscoverLegalPerspective />

        <div
          className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8  bg-[#D1D3D7]  bg-opacity-10 backdrop-filter backdrop-blur-lg border border-white
       shadow-[0 1px 26px 0 rgba(187, 199, 215, 0.5]
       border-opacity-40 rounded-xl px-[4%] py-[4%] md:px-[4%] md:py-[4%] xl:px-[8%] xl:py-[6%] 2xl:px-[8%] 2xl:py-[6%] min-w-[60%]'
        >
          {[...Array(8)].map((_, index) => (
            <div
              key={index}
              className='bg-white rounded-lg shadow-md overflow-hidden'
            >
              <div className='relative'>
                <img
                  src={placeholderImage}
                  alt='Thumbnail'
                  className='w-full h-40 object-cover'
                />
                <div className='absolute top-2 left-2 bg-[#D2F6FF] px-2 py-1 rounded-full text-xs font-medium text-[#101828]'>
                  7th Jan, 22
                </div>
              </div>
              <div className='p-4'>
                <h3 className='font-medium text-lg mb-1 text-[#101828] line-clamp-2'>
                  Title
                </h3>
                <p className='text-sm text-[#6E7785] mb-3 line-clamp-2'>
                  Complement
                </p>
                <div className='flex flex-wrap gap-2'>
                  {['Commercial', 'Conveyancing', 'Legal long title'].map(
                    (tag, tagIndex) => (
                      <span
                        key={tagIndex}
                        className={`text-xs font-medium px-2 py-1 rounded ${
                          tagIndex % 2 === 0 ? 'bg-[#EBF3FF]' : 'bg-[#D2F6FF]'
                        } text-[#101828]`}
                      >
                        {tag}
                      </span>
                    ),
                  )}
                </div>
              </div>
              <div className='px-2 pb-1 flex items-center justify-end'>
                <div className='bg-[#D2F6FF] rounded-full px-3 py-1'>
                  <div className='flex items-center justify-start'>
                    <img
                      src={cardThumbnail}
                      alt='Richard Cammin'
                      className='w-6 h-6 rounded-full mr-2 '
                    />
                    <div className='flex flex-col items-start justify-start'>
                      <span className='text-xs text-[#6E7785]'>
                        Richard Cammin
                      </span>
                      <span className='text-xs text-[#6E7785]'>Solicitor</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <img
            src={discoverPageBackground11}
            alt=''
            className='absolute w-full z-[-999]'
          />
        </div>
        <Button
          className='text-center mt-8 mx-auto w-[20%] sm:w-[30%] md:w-[10%] shadow-[0_1px_4px_rgba(0,0,0,0.25)]'
          onClick={() => {}}
        >
          Show more
        </Button>
        <div className=' mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-[8%]'>
          <h1 className='text-2xl sm:text-3xl md:text-4xl font-bold mb-8 '>
            Discover Law - Podcast
          </h1>
          <img
            src={discoverPageBackground2}
            alt=''
            className='absolute w-full z-[-999]  -translate-x-[20%]'
          />

          <section className='rounded-lg p-6 mb-12 relative'>
            <div className='flex flex-col lg:flex-row justify-between relative z-10 space-y-6 lg:space-y-0 lg:space-x-6'>
              <div className='lg:w-1/2'>
                <AudioPlayer
                  title='Legal Utopia Discover the Law Jodie Hill'
                  author='Legal Utopia'
                />
              </div>
              <div className='lg:w-1/2'>
                <div className='bg-[#001A3D] text-white rounded-lg p-6'>
                  <h3 className='font-semibold mb-4'>About this podcast</h3>
                  <p className='text-sm'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <img
            src={discoverPageBackground22}
            alt='discover page background image'
            className='absolute w-full z-[-999] translate-y-[50%] translate-x-[20%]'
          />

          <PodcastSection />

          <DiscoverMore />

          <LifeThroughLegalLensSection />

          <InterviewsSection />

          <p className='text-[#101828] pb-2 pt-12 text-center'>
            Looking to contribute? Got something to share?
          </p>
          <Button
            className='text-center mt-8 mx-auto w-[20%] sm:w-[30%] md:w-[10%] '
            onClick={() => {}}
          >
            Email us
          </Button>
        </div>
      </main>
    </div>
  )
}
