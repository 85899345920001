import React from 'react'
import { Button } from '../../../../../common/components/Authentication/Button'
import backgroundImg from '../../../assets/home_background_3.svg'

const HomeAboutUs = () => {
  return (
    <div className='w-full relative'>
      <div className='text-center mt-[20vh] sm:mt-[25vh] md:mt-[30vh] lg:mt-[35vh] xl:mt-[40vh] 2xl:mt-[41.67vh]'>
        <div className='max-w-4xl mx-auto px-4'>
          <h1 className='text-2xl sm:text-3xl font-bold leading-normal tracking-[0.4px] text-[#37A2BA] mb-2'>
            Our everyday is making law work for everyone.
          </h1>
          <h2 className='text-xl sm:text-2xl font-semibold leading-normal tracking-[0.36px] text-[#37A2BA] mb-8'>
            We're the people making that happen.
          </h2>
          <Button
            className='mx-auto w-[30%] sm:!w-[30%] lg:w-[20%] py-2 text-sm sm:text-base'
            onClick={() => {
              window.open('https://legalutopia.co.uk/whoweare', '_blank')
            }}
          >
            Who we are
          </Button>
        </div>
      </div>
      <div
        className='w-full h-[20vh] sm:h-[25vh] md:h-[30vh] lg:h-[35vh] xl:h-[40vh] 2xl:h-[41.67vh]
        mt-[5vh] sm:mt-[6vh] md:mt-[7vh] lg:mt-[8vh] xl:mt-[9vh] 2xl:mt-[9.26vh]
        '
        style={{
          backgroundImage: `url('${backgroundImg}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
    </div>
  )
}

export default HomeAboutUs
