import React from 'react'
import { useFirebaseCloudStorage } from '../../../common/firebase/useFirebaseCloudStorage'

export const FeaturedArticle = () => {
  const { getImages, getAudios, loading, error } =
    useFirebaseCloudStorage('images')
  const images = getImages()

  return (
    <section className='py-4 sm:py-6 md:py-8 lg:py-10 px-[4%] sm:px-[4%] md:px-[2%] lg:px-[2%] xl:px-[4%] '>
      <div className='mx-auto flex flex-col md:flex-row items-start gap-[10%]'>
        <div className='flex-[0_0_40%]'>
          <h2 className='text-white text-lg sm:text-xl md:text-2xl lg:text-2xl font-semibold text-blue-900 mb-2'>
            Featured Article
          </h2>
          <h3 className='text-2xl sm:text-3xl md:text-2xl lg:text-3xl font-bold text-[#101828] mb-4 leading-tight'>
            Economic Crime and Corporate Transparency law comes into effect,
            what are the new obligations on company directors?
          </h3>
          <a href='#' className='text-sm text-[#001A3D] underline'>
            Read now
          </a>
        </div>
        <div />
        <div className='flex-[0_0_40%] aspect-[15/7] pt-4 pb-2 lg:pt-0 lg:pb-0'>
          {loading ? (
            <div className='bg-[#101828] w-full h-full rounded-lg flex items-center justify-center text-white'>
              Loading...
            </div>
          ) : error ? (
            <div className='bg-[#101828] w-full h-full rounded-lg flex items-center justify-center text-white'>
              Error loading image
            </div>
          ) : images.length > 0 ? (
            <img
              src={images[1]}
              alt='Featured Article'
              className='w-full h-full object-fit rounded-lg'
            />
          ) : (
            <div className='bg-[#101828] w-full h-full rounded-lg flex items-center justify-center text-white'>
              No image available
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
