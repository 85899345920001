import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import useLawyerAvailability from 'common/providers/useLawyerAvailability'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import TimeSlot from './TimeSlot'
import AddBookingDetail from './AddBookingDetail'
import useProfile from 'common/providers/useProfile'
import useAppointmentBooking from 'common/providers/useAppointmentBooking'
import {
  LawyerListObject,
  BarristerProfile,
  AccountType,
} from 'common/state-type'
import BookingInfo from './BookingInfo'
import HeaderBar from './HeaderBar'

interface CreateBookingProps {
  lawyer: LawyerListObject | BarristerProfile
  lawyerType: AccountType
  handleClose: () => void
}

const CreateBooking: React.FC<CreateBookingProps> = ({
  lawyer,
  lawyerType,
  handleClose,
}) => {
  const navigate = useNavigate()
  const { profile } = useProfile()
  const { createAppointmentBooking, appointmentBookingLoading } =
    useAppointmentBooking()
  const { getLawyerAvailability, lawyerAvailability, lawyerAvailabilityError } =
    useLawyerAvailability()
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [appointmentDetail, setAppointmentDetail] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedSlot, setSelectedSlot] = useState<undefined | string>()
  const hasOpacity = selectedSlot && 'opacity-50'

  useEffect(() => {
    getLawyerAvailability({
      profile_id: lawyer.id,
      on_date: selectedDate.toISOString(),
      lawyerType: lawyerType,
    })
  }, [selectedDate, lawyer.id])

  useEffect(() => {
    setSelectedDate(new Date())
    setSelectedSlot(undefined)
    setHasConfirmed(false)
  }, [lawyer.id])

  const solicitor = lawyer as LawyerListObject
  const barrister = lawyer as BarristerProfile

  const getTimeData = () => {
    const startTime = moment('8:00', 'HH:mm')
    const endTime = moment('18:30', 'HH:mm')
    const allTimeSlots = []
    while (startTime < endTime) {
      allTimeSlots.push(startTime.format('HH:mm'))
      startTime.add(30, 'minutes')
    }
    const newTimeSlots = allTimeSlots.map((item) => {
      const isAvailable = lawyerAvailability
        ?.map((availailability) =>
          moment(availailability.start).utc().format('HH:mm'),
        )
        .includes(item)
      return {
        time: item,
        isAvailable,
      }
    })
    return newTimeSlots
  }

  const onClose = () => {
    if (selectedSlot) {
      setSelectedSlot(undefined)
    } else {
      handleClose()
    }
  }

  const onConfirm = (item: any) => {
    if (profile?.id) {
      setHasConfirmed(true)
    } else {
      navigate('/login')
    }
  }

  const getAvailibility = (time: string, available: boolean) => {
    if (available) {
      if (selectedSlot) {
        if (selectedSlot === time) {
          return 'selected'
        }
        return 'disabled'
      }
      return 'available'
    }
    return 'unavailable'
  }

  const toAppointmentDetail = () => {
    setAppointmentDetail(true)
  }

  const onCreateAppointment = (values: any) => {
    const date = moment(selectedDate).format('YYYY-MM-DD')
    const startDate = `${date} ${selectedSlot} UTC`
    const endDate = `${date} ${moment(selectedSlot, 'HH:mm')
      .add(30, 'minutes')
      .format('HH:mm')} UTC`
    const IsoStartDate = new Date(startDate).toISOString()
    const IsoEndDate = new Date(endDate).toISOString()
    const type = Utils.getUserType(lawyerType)

    const guestEmails = values.guests
      .filter((guest: any) => guest.email !== '')
      .map((guest: any) => guest.email)

    const appointmentDetail = {
      [type]: barrister.id ?? solicitor.id,
      start: IsoStartDate,
      end: IsoEndDate,
      ...values,
      guests: guestEmails,
    }

    createAppointmentBooking(appointmentDetail, {
      onSuccess: () => {
        handleClose()
        navigate('/appointments')
      },
      onError: () => {
        // handleClose()
      },
    })
  }

  const officeName =
    lawyerType === AccountType.LAWYER
      ? solicitor.office.name
      : barrister.chamber_name
  const fullName =
    lawyerType === AccountType.LAWYER
      ? `${solicitor.user.first_name} ${solicitor.user.last_name}`
      : barrister.full_name

  if (appointmentDetail) {
    return (
      <AddBookingDetail
        getBookingDetail={onCreateAppointment}
        isLoading={appointmentBookingLoading}
        avatar={lawyer.avatar}
        onClose={() => {
          setSelectedSlot(undefined)
          handleClose()
        }}
        officeName={officeName}
        fullName={fullName}
      />
    )
  }
  if (hasConfirmed) {
    return (
      <BookingInfo
        officeName={officeName}
        fullName={fullName}
        date={selectedDate}
        time={selectedSlot}
        onConfirm={toAppointmentDetail}
        lawyerType={lawyerType}
        avatar={lawyer.avatar}
        onClose={() => {
          setSelectedSlot(undefined)
          handleClose()
        }}
      />
    )
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <HeaderBar
        avatar={lawyer.avatar}
        fullName={fullName}
        officeName={officeName}
        onClose={onClose}
      />
      <div className='overflow-y-scroll px-3 pt-3'>
        <div className='items-center flex flex-col text-black'>
          <span className={`text-base ${hasOpacity}`}>
            {moment(selectedDate).format('dddd')}
          </span>
          <div className={`text-gray-400 flex-row pt-2 ${hasOpacity}`}>
            <DatePicker
              className='block p-4'
              disabled={selectedSlot ? true : false}
              disablePast
              inputFormat='Do MMMM YYYY'
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue)
              }}
              renderInput={(params) => {
                const newParams = {
                  ...params,
                  inputProps: { ...params.inputProps, disabled: true },
                }
                return <TextField {...newParams} />
              }}
            />
          </div>
        </div>
        <div className='mt-4'>
          {getTimeData()?.map((item, index) => {
            return (
              <TimeSlot
                time={item.time}
                isAvailable={item.isAvailable}
                key={index}
                handleAvalilablePress={(time) => {
                  setSelectedSlot(time)
                }}
                availailability={getAvailibility(item.time, item.isAvailable)}
                handleConfirm={() => onConfirm(item)}
              />
            )
          })}
          <div className='h-[1px] w-full bg-lue-purple-400' />
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default CreateBooking
