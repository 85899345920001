import React from 'react'
import { InputBase, Button, Menu, MenuItem } from '@mui/material'
import { DropdownArrowIcon } from '../../../src/common/components/Icons/DropdownArrowIcon'

export const SearchAndFilter = ({
  onSearch,
  onFilter,
  onClearFilters,
  filterOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ],
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className='flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-4 px-2 md:px-[4%]'>
      <div className='relative w-full md:w-[400px]'>
        <InputBase
          placeholder='Search...'
          fullWidth
          sx={{
            backgroundColor: 'white',
            height: { xs: '36px', md: '40px' },
            padding: { xs: '0 10px', md: '0 14px' },
            borderRadius: { xs: '6px', md: '8px' },
            border: '0.8px solid #F4F4F4',
            boxShadow: '0px 0.4px 2px 0px rgba(0, 0, 0, 0.25)',
            '& input': {
              padding: { xs: '6px 0', md: '8px 0' },
              color: '#818285',
              fontSize: { xs: '14px', md: '16px' },
            },
          }}
          endAdornment={
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4 md:h-5 md:w-5 text-gray-400 ml-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              />
            </svg>
          }
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <div className='flex items-center space-x-2 md:space-x-4 w-full md:w-auto'>
        <Button
          variant='outlined'
          endIcon={
            <DropdownArrowIcon className='text-[#37A2BA] w-3 h-3 md:w-4 md:h-4' />
          }
          onClick={handleClick}
          sx={{
            color: '#333',
            borderColor: '#e0e0e0',
            backgroundColor: 'white',
            textTransform: 'none',
            padding: { xs: '6px 10px', md: '8px 16px' },
            minWidth: { xs: '100px', md: '120px' },
            justifyContent: 'space-between',
            borderRadius: { xs: '6px', md: '8px' },
            fontSize: { xs: '12px', md: '14px' },
            '&:hover': {
              backgroundColor: '#f5f5f5',
              borderColor: '#e0e0e0',
            },
          }}
        >
          <span className='hidden md:inline'>Filter by</span>
          <span className='md:hidden'>Filter</span>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiMenu-paper': {
              borderRadius: '8px',
              border: '0.8px solid #F4F4F4',
              boxShadow: '0px 0.4px 2px 0px rgba(0, 0, 0, 0.25)',
            },
          }}
        >
          {filterOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                onFilter(option.value)
                handleClose()
              }}
              sx={{ fontSize: { xs: '14px', md: '16px' } }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <Button
          variant='text'
          onClick={onClearFilters}
          sx={{
            color: '#6B7280',
            textTransform: 'none',
            padding: { xs: '6px 0', md: '8px 0' },
            fontSize: { xs: '12px', md: '14px' },
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          <span className='hidden md:inline'>Clear filters</span>
          <span className='md:hidden'>Clear</span>
        </Button>
      </div>
    </div>
  )
}
