// Optional but if used means within our providers we can
import { CognitoUser } from 'amazon-cognito-identity-js'

export enum AccountType {
  CONSUMER = 'CONSUMER',
  BUSINESS = 'BUSINESS',
  LAWYER = 'LAWYER',
  BARRISTER = 'BARRISTER',
}

export type KeyValue = {
  label: string
  value: number
}

export enum SortBy {
  PRICE = 'hourly_rate',
  RATING = 'average_rating',
  NAME = 'user__first_name',
  DISTANCE = 'distance',
  PRICE_OFFICE = 'average_hourly_rate_pence',
  NAME_OFFICE = 'name',
}

export type StripeProduct = {
  id: string
  stripe_products: {
    id: string
    object: string
    active: boolean
    attributes: any[]
    created: number
    default_price: {
      id: string
      object: string
      active: boolean
      billing_scheme: string
      created: number
      currency: string
      custom_unit_amount?: any
      livemode: boolean
      lookup_key?: any
      metadata: {}
      nickname?: any
      product: string
      recurring: {
        aggregate_usage?: any
        interval: string
        interval_count: number
        trial_period_days?: any
        usage_type: string
      }
      tax_behavior: string
      tiers_mode?: any
      transform_quantity?: any
      type: string
      unit_amount: number
      unit_amount_decimal: string
    }
    description?: any
    images: any[]
    livemode: boolean
    metadata: {
      lu_id: string
    }
    name: string
    package_dimensions?: any
    shippable?: any
    statement_descriptor?: any
    tax_code?: any
    type: string
    unit_label?: any
    updated: number
    url?: any
  }[]
  stripe_coupons: any[]
  created_at: Date
  updated_at: Date
  name: string
  description?: any
  type: string
  stripe_product_ids: string[]
  stripe_coupon_ids: any[]
  features: any[]
}

export enum MemberStatus {
  DOOR_TENANT = 'DOOR_TENANT',
  DUAL_CAPACITY = 'DUAL_CAPACITY',
  EMPLOYEE_BSB_AUTHORISED_BODY = 'EMPLOYEE_BSB_AUTHORISED_BODY',
  EMPLOYEE_NON_BSB_AUTHORISED_BODY = 'EMPLOYEE_NON_BSB_AUTHORISED_BODY',
  EMPLOYEE_NON_BSB_LICENSED_BODY = 'DOOR_TENEMPLOYEE_NON_BSB_LICENSED_BODYANT',
  MANAGER_BSB_AUTHORISED_BODY = 'MANAGER_BSB_AUTHORISED_BODY',
  MANAGER_BSB_LICENSED_BODY = 'MANAGER_BSB_LICENSED_BODY',
  MANAGER_NON_BSB_AUTHORISED_BODY = 'MANAGER_NON_BSB_AUTHORISED_BODY',
  MANAGER_NON_BSB_LICENSED_BODY = 'MANAGER_NON_BSB_LICENSED_BODY',
  PROBATIONARY_TENANT = 'PROBATIONARY_TENANT',
  SOLE_PRACTITIONER = 'SOLE_PRACTITIONER',
  SQUATTER = 'SQUATTER',
  TENANT = 'TENANT',
  THIRD_SIX_PUPIL = 'THIRD_SIX_PUPIL',
}
export type LawyerSubscription = {
  subscription_type: 'PREMIUM' | 'STANDARD'
  cancelled: boolean
  expiry_date: string
  id: string
}
export interface LawyerBookingProfile {
  id: string
  bio: string
  job_title: string
  number_of_practice_years: number
  sra_authorised: boolean
  linkedin_profile_url: string
  authenticated_with_cronofy: boolean
  organisation: Organisation
  avatar: string
  user: User
}

export interface LawyerListObject {
  id: string
  bio: string
  avatar: string
  user: User
  organisation: Organisation
  office: Office
  linkedin_profile_url: string
  google_business_url: string
  trust_pilot_reviews_url?: string
  google_reviews_url?: string
  twitter_profile_url: string
  is_partner: boolean
  title: string
  average_rating: null
  sra_authorised: boolean
  qualifications: Qualification[]
  memberships: Membership[]
  sra_number: string
  website_url: string
  phone_number: string
  number_of_practice_years: number
  rate_per_hour_excluding_vat: number
  rate_per_hour_including_vat: number
  favourite_id?: string
  membership_type: MembershipType
}
export interface ProfileReview {
  id: string
  user: User
  lawyer?: string
  barrister?: string
  rating: number
  review: string
  created_at: Date
  requested_removal: boolean
  lawyer_rating_responses?: ReviewResponse[]
  barrister_rating_responses?: ReviewResponse[]
}

export interface ReviewResponse {
  id: string
  response: string
  lawyer_rating?: string
  barrister_rating?: string
  created_at: Date
  user?: User
}

export interface Office {
  id: string
  name: string
  town: string
  country: string
  phone_number: string
  email_address: null
  address_line_1: string
  address_line_2: null
  address_line_3: null
  address_line_4: null
  postal_code: string
  latitude: number
  organisation: Organisation
  average_hourly_rate_pence: null
  longitude: number
}
export interface AreasOfPractice {
  id: string
  name: string
  category: string
  icon: null
}
interface CronofyUser {
  sub: string
  authorized_with_zoom: boolean
}
type LawyerStatus = 'EMPLOYEE' | 'CONSULTANT' | null
type PrimaryStatus = 'EMPLOYED' | 'SELF_EMPLOYED' | 'DUAL_CAPACITY'
type ISubscriptionCode = {
  code: string
}
export interface LawyerProfile {
  id: string
  bio: string
  avatar: string
  user: User
  title: string
  lawyer_ratings?: ProfileReview[]
  lawyer_subscription_code?: ISubscriptionCode[]
  organisation?: Organisation
  linkedin_profile_url: string
  number_of_practice_years: string
  sra_authorised: boolean
  sra_id: number
  authenticated_with_cronofy: string
  phone_number: string
  including_vat: boolean
  start_practing_on: string
  website_url: string
  job_title: string
  primary_status: string
  cronofy_user?: CronofyUser
  qualifications: string[]
  course_site: string
  qualification_abbr: string
  memberships: string[]
  hourly_rate: number
  trustpilot_reviews_url: string
  twitter_url: string
  industries: string[]
  business_categories: string[]
  legal_documents: string[]
  areas_of_law: string[]
  leagl_services: string[]
  status: LawyerStatus
  date_of_call?: string
  areas_of_practice: string[]
  subscriptions?: LawyerSubscription[]
  membership_type: MembershipType
  pays_commission: boolean
}

export interface Honour {
  id: string
  name: string
}

export interface BarristerProfile {
  id: string
  favourite_id?: string
  address_line_1?: string
  address_line_2?: string
  areas_of_practice?: string[]
  authenticated_with_cronofy?: string
  barrister_ratings?: ProfileReview
  barrister_subscription_code?: ISubscriptionCode[]
  avatar?: string
  average_rating?: number | null
  bio?: string
  chamber_name?: string
  county?: string
  cronofy_user?: CronofyUser
  date_of_call?: string
  email_address?: string
  fee_transparency_policy_url?: string
  forenames?: string
  full_name?: string
  google_business_url?: string
  honours?: Honour[]
  hourly_rate?: number
  including_vat?: number
  start_practing_on: string
  latitude?: number
  linkedin_profile_url?: string
  longitude?: number
  member_status?: MemberStatus
  membership_type?: MembershipType
  rate_per_hour_excluding_vat?: number
  rate_per_hour_including_vat?: number
  number_of_practice_years?: string
  phone_number?: string
  postal_code?: string
  primary_status?: PrimaryStatus
  public_access?: true | false | null
  qualifications: Qualification[]
  memberships: Membership[]
  sra_authorised?: boolean
  surnames?: string
  title?: string
  town?: string
  minimum_fee?: string
  user?: User
  twitter_profile_url?: string
  website_url?: string
  subscriptions?: LawyerSubscription[]
  trust_pilot_reviews_url?: string
  google_reviews_url?: string
  pays_commission: boolean
}

export interface Membership {
  id: string
  name: string
}

export interface Qualification {
  id: string
  name: string
}

interface DetailedLawyerProfile {
  id: string
  bio: string
  job_title: string
  number_of_practice_years: number
  sra_authorised: boolean
  linkedin_profile_url: string
  authenticated_with_cronofy: boolean
  organisation: Organisation
  avatar: string
  user: User
  phone_number: string
  website_url: string
  cronofy_user: CronofyUser
  qualifications: string[]
  memberships: string[]
  areas_of_practice: string[]
}

export interface DetailedBooking {
  id: string
  lawyer: LawyerBookingProfile
  start: string
  end: string
  is_video: boolean
  notes: string
  accessibility_requirements: string
  status: string
  identification_document?: File
  proof_of_address?: File
  companies_house_company_number: string
  user: User
  event_details: {
    conferencing: {
      join_url: string
    }
  }
  lawyerType?: AccountType
}

type BookALawyerRequest = {
  lawyer: string
  id: string
  identification_document: File
  proof_of_address: File
  start: string
  end: string
  notes: string
  is_video: boolean
  accessibility_requirements?: string
  companies_house_company_number?: string
}
export type Product = {
  title: string
  description: string
  price: string
  currency: string
  localizedPrice: string
  type: 'inapp' | 'iap'
  productId: string
}
export type SubscriptionDTO = {
  id: string
  created_at: string
  updated_at: string
  platform: string
  product_id: string
  expiry_date: string
}[]

export type GovernmentService = {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  url: string
  logo: string
  group: {
    id: string
    name: string
  }
}

export interface OfficeSummary {
  id: string
  name: string
  town: string
  country: string
  latitude: number
  longitude: number
  organisation: OfficeBody
  phone_number: string
  website_url: string
  email_address: string
  average_regional_cost_pence: null
  average_hourly_rate_pence: null
  legal_aid_practitioner: boolean
  government_contracted_areas_of_practice: string[]
  favourite_id: null
}

export interface OfficeBody {
  id: string
  practice_name: string
  description: string
  company_structure: string
  company_registration_number: string
  authorisation_date: string
  fee_transparency_policy_url?: string
  premium: boolean
  logo: string | null
  membership_type: MembershipType
  practice_name_initials: string
  lawyers: LawfirmMember[]
  areas_of_practice: AreasOfPractice[]
  sra_number: number
  business_type: string
  trustpilot_reviews_url?: string
  average_rating?: number
  google_reviews_url?: string
  linkedin_profile_url: null
  twitter_profile_url: null
  google_business_profile_url: null
  regulatory_body_type: string
  sra_organisation_id: number
}

export type LawfirmMember = {
  avatar: string
  id: string
  name: string
  bio: string
  job_title: string
  user: User
}

export type Barrister = {
  id: string
  title: string
  forenames: string
  surnames: string
  full_name: string
  member_status: string
  primary_status: PrimaryStatus
  date_of_call: string
  honours: any[]
  latitude: number
  longitude: number
  email_address: string
  phone_number: string
  website_url: string
}

export type BarristerDetail = {
  id: string
  title: string
  forenames: string
  surnames: string
  full_name: string
  member_status: string
  primary_status: PrimaryStatus
  date_of_call: string
  honours: string[]
  latitude: number
  longitude: number
  email_address: string
  phone_number: string
  website_url: string
  bio: string
  areas_of_practice: string[]
}

export enum PurchaseStateAndroid {
  UNSPECIFIED_STATE = 0,
  PURCHASED = 1,
  PENDING = 2,
}
export type CreditItem = {
  id: string
  quantity: number
  remaining_credits: number
  consumed_credits: number
  expires_at: string
}

export type FcmDevice = {
  id?: string
  registration_id: string
  active?: boolean
}

export type FcmDevices = FcmDevice[]

export type Purchase = {
  productId: string
  transactionId?: string
  transactionDate: number
  transactionReceipt: string
  purchaseToken?: string
  dataAndroid?: string
  signatureAndroid?: string
  autoRenewingAndroid?: boolean
  purchaseStateAndroid?: PurchaseStateAndroid
  originalTransactionDateIOS?: string | number
  originalTransactionIdentifierIOS?: string
  isAcknowledgedAndroid?: boolean
  userIdAmazon?: string
  userMarketplaceAmazon?: string
  userJsonAmazon?: string
}

export type Subscription = Product & {
  type: 'subs' | 'sub'
  productId: string
  discounts?: {
    identifier: string
    type: string
    numberOfPeriods: string
    price: string
    localizedPrice: string
    paymentMode: string
    subscriptionPeriod: string
  }[]
  introductoryPrice?: string
  introductoryPricePaymentModeIOS?: string
  introductoryPriceNumberOfPeriodsIOS?: string
  introductoryPriceSubscriptionPeriodIOS?: string
  subscriptionPeriodNumberIOS?: string
  subscriptionPeriodUnitIOS?: string
  introductoryPriceCyclesAndroid?: string
  introductoryPricePeriodAndroid?: string
  subscriptionPeriodAndroid?: string
  freeTrialPeriodAndroid?: string
}
export type Organisation = {
  id: string
  practice_name: string
  description: string
  company_structure: string
  company_registration_number: string
  authorisation_date: Date
  premium: boolean
  logo: null
  membership_type: MembershipType
  practice_name_initials: string
  lawyers: Lawyer[]
  areas_of_practice: AreasOfPractice[]
  sra_number: number
  sra_organisation_id: number
  business_type: string
  trustpilot_reviews_url: string
  linkedin_profile_url: null
  twitter_profile_url: null
  google_business_profile_url: null
  regulatory_body_type: string
  fee_transparency_policy_url: string
}

export interface AreasOfPractice {
  id: string
  name: string
  category: string
  icon: null
}

export enum MembershipType {
  NONE = 'NONE',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}
export type OrganisationDetail = {
  id: string
  practice_name: string
  description?: string
  company_structure: string
  company_registration_number: string
  authorisation_date: string
  areas_of_practice: string[]
  premium: boolean
  logo?: string
  membership_type: MembershipType
  practice_name_initials: string
  sra_number: number
  sra_organisation_id: number
  business_type: string
  trustpilot_reviews_url: string
  twitter_profile_url: string
  google_business_profile_url: string
  linked_in_profile_url: string
  fee_transparency_policy_url: string
}

export enum CreditType {
  LEGAL_RESULTS = 'LEGAL_RESULTS',
}
export type PageResult<T> = {
  next: string | undefined
  previous: string | undefined
  results: T[]
  count?: number
}
export type SubDomain = {
  display_name: string
  id: string
  icon: string
}

export type Domain = {
  id: string
  display_name: string
  label: string
  icon: string
  sub_domains?: SubDomain[]
}

export type LegalAidQuestion = {
  id: string
  question_text: string
  choices: string[] | null
}
export type LegalAidContentItem = {
  id: string
  icon: string
  logo: string
  name: string
  location: string
  phone_number: string
  url: string
  description: string
}
export type feedbackItem = {
  id: string
  score: number
  title: string
  positive_feedback?: string
  improvement_feedback?: string
  concern_feedback?: string
  support_feedback?: string
  created_at: string
}

export type membershipRedemptionItem = {
  id: string
  created_at: string
  //TODO
}

export type resultItem = {
  id: string
  created_at: string
  updated_at: string
  unlocked: boolean
  sub_domain: {
    id: string
    display_name: string
    diagnosis_content?: any
    domain: {
      id: string
      display_name: string
      label: string
      diagnosis_content?: any
      score: number
      title: string
      positive_feedback: string
      improvement_feedback: string
      created_at: string
      user: User
    }
  }
  role_description: {
    id: string
    description: string
    role: {
      id: string
      name: string
    }
  }
  status_description: {
    id: string
    description: string
    status: {
      id: string
      name: string
    }
  }
  remedy_description: {
    id: string
    description: string
    remedy: {
      id: string
      name: string
    }
  }
  scenario: {
    id: string
    scenario_text: string
    legal_aid: boolean
  }
  successful_legal_aid_question: string
  user_submission?: any
  result_status: string
  has_services: number
  has_guidance: boolean
  has_portals: number
  has_resources: number
  last_viewed_at: string
  updated_since_last_view: boolean
}
export interface User {
  id: string
  first_name: string
  last_name: string
  email: string
}

interface Lawyer {
  id: string
  bio: string
  job_title: string
  number_of_practice_years: number
  sra_authorised: boolean
  linkedin_profile_url: string
  authenticated_with_cronofy: boolean
  organisation: Organisation
  avatar: string
  user: User
}

interface BusinessProfile {
  address_line_1: string
  address_line_2: string
  town: string
  county?: string
  postal_code: string
  business_name: string
  business_type: string
  industries: string[]
  company_number?: string
}

export const howYouHeardOptions = [
  'FRIEND',
  'TWITTER',
  'INSTAGRAM',
  'LINKEDIN',
  'OTHER',
]

export interface Profile {
  id: string
  username: string
  locale?: string
  avatar?: string
  email: string
  premium: boolean
  date_of_birth: string | null
  gender: 'MALE' | 'FEMALE' | 'OTHER' | null
  how_user_heard_lu:
    | 'FRIEND'
    | 'TWITTER'
    | 'INSTAGRAM'
    | 'LINKEDIN'
    | 'OTHER'
    | null
  phone_number?: string | null
  first_name: string
  last_name: string
  address_line_1?: string
  address_line_2?: string
  town?: string
  county?: string
  postal_code?: string
  account_type: AccountType
  accepted_marketing_policy: boolean
  prevent_analytics_tracking?: boolean
  business_profile?: BusinessProfile
  subscriptions?: LawyerSubscription[]
  personal_invite_token?: {
    id: string
    token: string
  }
  used_invite_token?: string
  has_made_purchase?: boolean
  occupation?: string
  subscription_active?: boolean
}

interface CronofyConferencingProfiles {
  provider_name: string
  profile_id: string
  profile_name: string
  profile_connected: boolean
  profile_relink_url: string
}

export type LegalCheckerResource = {
  asset?: string
  description: string
  id: string
  logo?: string
  name: string
  price?: number
  type: 'CONTRACT_TEMPLATE' | 'GUIDANCE_DOCUMENT' | 'WEBSITE'
  url: string
}

export enum Status {
  ACCEPTED = 'ACCEPTED',
  PENDING_LAWYER_ACCEPTANCE = 'PENDING_LAWYER_ACCEPTANCE',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
}

export enum BookingStatus {
  PENDING_LAWYER_ACCEPTANCE = 'PENDING_LAWYER_ACCEPTANCE',
  PENDING_BARRISTER_ACCEPTANCE = 'PENDING_BARRISTER_ACCEPTANCE',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  COMPLETE = 'COMPLETE',
}

export interface OfficeDetail {
  id: string
  practice_name: string
  description: string
  short_description: string
  company_structure: string
  company_registration_number: string
  authorisation_date: string
  areas_of_practice: string[]
  premium: boolean
  logo: string
  membership_type: MembershipType
  practice_name_initials: string
  sra_number: number
  sra_organisation_id: number
  business_type: string
  trustpilot_reviews_url?: string
  linkedin_profile_url: string
  twitter_profile_url: string
  google_business_profile_url: string
  fee_transparency_policy_url: string
}

export interface Booking {
  id: string
  barrister?: BarristerProfile
  lawyer?: LawyerListObject
  start: Date
  end: Date
  is_video: boolean
  notes: string
  accessibility_requirements?: string
  status: BookingStatus
  identification_document?: string | File
  proof_of_address?: string | File
  companies_house_company_number?: string
  user: User
  guests: string[]
  rating_id?: string
}

type CreateAppointmentBookingRequest = {
  lawyer?: string
  barrister?: string
  start: string
  end: string
  notes?: string
  is_video?: boolean
  accessibility_requirements?: string
  companies_house_company_number?: string
  guest_list?: string[]
}
export interface AppState {
  [extraProps: string]: any // Means that extra props are fine
  userLoading?: boolean
  userError?: string
  theme?: {}
  inviteLink?: string
  acceptedLink?: string
  user?: {
    first_name: string
    last_name: string
    [extraProps: string]: any
  }
  legalCheckerResources?: LegalCheckerResource[]
  legalCheckerServices?: PageResult<OfficeSummary>
  legalCheckerServicesLoading?: boolean
  legalDocumentTypes?: {
    id: string
    name: string
    stripe_product_id?: string
    ios_product_id?: string
    android_product_id?: string
  }[]
  legalCheckerMeta?: {
    // todo: this needs to match post
    selectedSubDomain?: {
      display_name: string
      id: string
    } | null
    secondaryDomain: null | {
      'id': string
      'display_name': string
    }
    primaryDomain: null | {
      'id': string
      'display_name': string
    }
    selectedDomain: null | {
      'id': string
      'display_name': string
    }
  }
  profileLoading?: boolean
  profileSaving?: boolean
  profileError?: string
  profile?: Profile
  feedbackSummaryLoading?: boolean
  feedbackSummarySaving?: boolean
  feedbackSummaryError?: string
  feedbackSummaryCustom: {
    total_ratings_1: number
    total_ratings_2: number
    total_ratings_3: number
    total_ratings_4: number
    total_ratings_5: number
  }
  feedbackSummary?: AppState['feedbackSummaryCustom'] & {
    average_score: number
    total_ratings: number
    total_ratings_1: number
    total_ratings_2: number
    total_ratings_3: number
    total_ratings_4: number
    total_ratings_5: number
  }
  feedbackLoading?: boolean
  feedbackSaving?: boolean
  feedbackError?: string
  feedback?: PageResult<feedbackItem>

  membershipRedemptionLoading?: boolean
  membershipRedemptionSaving?: boolean
  membershipRedemptionError?: string
  membershipRedemption: membershipRedemptionItem[]

  legalCheckerResults?: {
    count: number
    next: string
    previous: string
    results: resultItem[]
  }
  faq?: {
    question_text: string
    response_text: string
  }
  message?: {
    question_text: string
    response_text: string
  }
  menuItems?: any
  faqs?: AppState['faq'][]
  faqsLoading?: boolean
  faqsSaving?: boolean
  faqsError?: string
  legalCheckerOptions?: {
    role: string
    status: string
    scenario: string
    remedy: string
    problemDescription: string
  }
  legalCheckerLoading?: boolean
  legalCheckerSaving?: boolean
  legalCheckerError?: string
  legalChecker: {
    roles: {
      id: string
      description: string
      role: {
        id: string
        name: string
        role: { id: string; name: string }
      }
    }[]
    statuses: {
      id: string
      description: string
      status: {
        id: string
        name: string
        status: { id: string; name: string }
      }
    }[]
    remedies: {
      id: string
      description: string
      remedy: {
        id: string
        name: string
        remedy: { id: string; name: string }
      }
    }[]
  }
  legalCheckerDomains?: Domain[]
  legalCheckerSubDomains?: Record<string, Domain[]>
  legalCheckerQuestionsLoading?: boolean
  legalCheckerQuestionsSaving?: boolean
  legalCheckerQuestionsError?: string
  legalCheckerQuestions: {
    'id': string
    'question_text': string
  }[]
  legalCheckerScenariosLoading?: boolean
  legalCheckerScenariosSaving?: boolean
  legalCheckerScenariosError?: string
  legalCheckerScenarios: {
    'id': string
    'scenario_text': string
    'legal_aid_question_sequence': string
  }[]
  legalCheckerSubmissionLoading?: boolean
  legalCheckerSubmissionSaving?: boolean
  legalCheckerSubmissionError?: string
  legalCheckerSubmission?: {
    'sample': string
    'id': string
    'primary_domain': null | {
      'id': string
      'display_name': string
    }
    'secondary_domain': null | {
      'id': string
      'display_name': string
    }
  }
  legalCheckerResultLoading?: boolean
  legalCheckerResultSaving?: boolean
  legalCheckerResultError?: string
  legalCheckerResult?: {
    id: string
    sub_domain?: string
    role_description?: string
    question_responses?: {
      question: string
      response: 'YES' | 'NO'
    }[]
    unlocked: boolean
    status_description?: string
    remedy_description?: string
    legal_aid_question_sequence_id?: string
    scenario?: string
    user_submission?: string
  }
  legalCheckerResultFull: resultItem
  legalAidQuestionsLoading?: boolean
  legalAidQuestionsSaving?: boolean
  legalAidQuestionsError?: string
  legalAidQuestions?: {
    id: string
    name: string
    questions: LegalAidQuestion[]
  }
  legalCheckerGuidanceLoading?: boolean
  legalCheckerGuidanceSaving?: boolean
  legalCheckerGuidanceError?: string
  legalCheckerGuidance?: {
    id: string
    updated_at: string
    feedback?: {
      id: string
      guidance: string
      legal_checker_result: string
      score: number
    }
    html_content: string
    faqs: [
      {
        id: string
        question_text: string
        response_text: string
      },
    ]
    recommendations: [
      {
        recommendation: {
          id: string
          updated_at: string
          html_content: string
          scenario: {
            id: string
            scenario_text: string
            legal_aid_question_sequence: string
            sub_domain: {
              id: string
              display_name: string
              diagnosis_content: string
              domain: string
            }
          }
        }
      },
    ]
  }
  legalCheckerFurtherGuidanceLoading?: boolean
  legalCheckerFurtherGuidanceSaving?: boolean
  legalCheckerFurtherGuidanceError?: string
  legalCheckerFurtherGuidance?: {
    id: string
    updated_at: string
    feedback?: {
      id: string
      guidance: string
      legal_checker_result: string
      score: number
    }
    html_content: string
    faqs: [
      {
        id: string
        question_text: string
        response_text: string
      },
    ]
    recommendations: [
      {
        recommendation: {
          id: string
          updated_at: string
          html_content: string
          scenario: {
            id: string
            scenario_text: string
            legal_aid_question_sequence: string
            sub_domain: {
              id: string
              display_name: string
              diagnosis_content: string
              domain: string
            }
          }
        }
      },
    ]
  }
  guidanceFeedbackLoading?: boolean
  guidanceFeedbackSaving?: boolean
  guidanceFeedbackError?: string
  guidanceFeedback?: {
    [extraProps: string]: any
  }
  legalAidContentLoading?: boolean
  legalAidContentSaving?: boolean
  legalAidContentError?: string
  legalAidContent?: LegalAidContentItem[]
  verifyCreditPurchaseLoading?: boolean
  verifyCreditPurchaseSaving?: boolean
  verifyCreditPurchaseError?: string
  verifyCreditPurchase?: {
    [extraProps: string]: any
  }
  iapsLoading?: boolean
  iapsSaving?: boolean
  iapsError?: string
  iaps?: Record<CreditType, Product[] | Subscription[]>
  subscriptionsLoading?: boolean
  subscriptionsSaving?: boolean
  subscriptionsError?: string
  subscriptions?: Record<CreditType, Purchase[]>
  resultCreditsLoading?: boolean
  resultCreditsSaving?: boolean
  resultCreditsError?: string
  resultCredits?: {
    items: CreditItem[]
    isSubscribed: boolean
    total: number
  }
  unlockLoading?: boolean
  unlockSaving?: boolean
  unlockError?: string
  unlock?: {
    [extraProps: string]: any
  }
  viewResultLoading?: boolean
  viewResultSaving?: boolean
  viewResultError?: string
  viewResult?: {
    [extraProps: string]: any
  }
  legalDocumentsLoading?: boolean
  legalDocumentsSaving?: boolean
  legalDocumentsError?: string
  legalDocuments?: PageResult<{
    id: string
    title: string
    description: string
    google_drive_url: string
    //add 'paid'
    file_url: string
    price: number
    is_new: boolean
    is_updated: boolean
  }>
  governmentServicesLoading?: boolean
  governmentServicesSaving?: boolean
  governmentServicesError?: string
  governmentServices?: {
    title: string
    data: GovernmentService[]
  }[]
  closeAccountLoading?: boolean
  closeAccountSaving?: boolean
  closeAccountError?: string
  closeAccount?: {
    password: string
  }
  covidRegulationsLoading?: boolean
  covidRegulationsSaving?: boolean
  covidRegulationsError?: string
  covidRegulations?: PageResult<{
    id: string
    title: string
    description: string
    google_drive_url: string
    file_url: string
    price: number
    is_new: boolean
    is_updated: boolean
  }>
  legalResultFeedbackLoading?: boolean
  legalResultFeedbackSaving?: boolean
  legalResultFeedbackError?: string
  legalResultFeedback?: {
    [extraProps: string]: any
  }
  industriesLoading?: boolean
  industriesSaving?: boolean
  industriesError?: string
  industries?: {
    name: string
    id: string
  }[]
  inviteTokenLoading?: boolean
  inviteTokenSaving?: boolean
  inviteTokenError?: string
  inviteToken?: {
    [extraProps: string]: any
  }
  areasOfPracticeLoading?: boolean
  areasOfPracticeSaving?: boolean
  areasOfPracticeError?: string
  areasOfPractice?: {
    'name': string
    'id': string
    category: string
    icon: string
    card_image?: string
    featured: boolean
    lawyer_count: number
    barrister_count: number
  }[]
  officesLoading?: boolean
  officesSaving?: boolean
  officesError?: string
  offices?: PageResult<OfficeSummary>
  officeLoading?: boolean
  officeSaving?: boolean
  officeError?: string
  office?: OfficeDetail
  areaCategoriesLoading?: boolean
  areaCategoriesSaving?: boolean
  areaCategoriesError?: string
  areaCategories?: {
    id: string
    name: string
  }[]
  documentCategoriesLoading?: boolean
  documentCategoriesSaving?: boolean
  documentCategoriesError?: string
  documentCategories?: {
    id: string
    name: string
  }[]
  lawyerAvailabilityLoading?: boolean
  lawyerAvailabilitySaving?: boolean
  lawyerAvailabilityError?: string
  lawyerAvailability?: {
    start: string
    end: string
  }[]
  challengeName?: {
    challengeName: string
  }
  passwordLoading?: boolean
  passwordSaving?: boolean
  passwordError?: string
  password?: {
    [extraProps: string]: any
  }
  lawyersProfileLoading?: boolean
  lawyersProfileSaving?: boolean
  lawyersProfileError?: string
  lawyersProfile?: LawyerProfile | BarristerProfile
  cronofyAuthLinkLoading?: boolean
  cronofyAuthLinkSaving?: boolean
  cronofyAuthLinkError?: string
  cronofyAuthLink?: {
    auth_link: string
  }
  authWithCronofyLoading?: boolean
  authWithCronofySaving?: boolean
  authWithCronofyError?: string
  authWithCronofy?: {
    id: string
    bio: string
    job_title: string
    number_of_practice_years: string
    sra_authorised: boolean
    linkedin_profile_url: string
    qualifications: string[]
    memberships: string[]
    areas_of_practice: string[]
    authenticated_with_cronofy: string
  }
  setupMfaLoading?: boolean
  setupMfaSaving?: boolean
  setupMfaError?: string
  setupMfa?: {
    code: string
  }
  verifyMfaLoading?: boolean
  verifyMfaSaving?: boolean
  verifyMfaError?: string
  verifyMfa?: {
    Status: string
  }
  cronofyUiTokenLoading?: boolean
  cronofyUiTokenSaving?: boolean
  cronofyUiTokenError?: string
  cronofyUiToken?: {
    element_token: string
  }
  lawyerProfilesLoading?: boolean
  lawyerProfilesSaving?: boolean
  lawyerProfilesError?: string
  lawyerProfiles?: PageResult<LawyerListObject>
  confirmSigninLoading?: boolean
  confirmSigninSaving?: boolean
  confirmSigninError?: string
  confirmSignin?: CognitoUser
  bookingsLoading?: boolean
  bookingsError?: string
  bookings?: PageResult<Booking>
  acceptedBookingsLoading?: boolean
  acceptedBookingsError?: string
  acceptedBookings?: PageResult<Booking>
  pendingBookingsLoading?: boolean
  pendingBookingsError?: string
  pendingBookings?: PageResult<Booking>
  declinedBookingsLoading?: boolean
  declinedBookingsError?: string
  declinedBookings?: PageResult<Booking>
  lawyerBookingsLoading?: boolean
  lawyerBookingsError?: string
  lawyerBookings?: PageResult<Booking>
  barristerBookingsLoading?: boolean
  barristerBookingsError?: string
  barristerBookings?: PageResult<Booking>
  bookALawyerLoading?: boolean
  bookALawyerSaving?: boolean
  bookALawyerError?: string
  bookALawyer?: {
    id: string
    start: string
    end: string
    note: string
    is_video: boolean
    accessibility_requirements: string
    state: string
  }
  bookALawyerAppointment?: BookALawyerRequest
  zoomAuthLinkLoading?: boolean
  zoomAuthLinkSaving?: boolean
  zoomAuthLinkError?: string
  zoomAuthLink?: {
    auth_link: string
  }
  companiesLoading?: boolean
  companiesSaving?: boolean
  companiesError?: string
  companies?: {
    company_name: string
    company_number: string
  }[]
  actionAppointmentLoading?: boolean
  actionAppointmentSaving?: boolean
  actionAppointmentError?: string
  actionAppointment?: {
    [extraProps: string]: any
  }
  cronofyConferencingProfilesLoading?: boolean
  cronofyConferencingProfilesSaving?: boolean
  cronofyConferencingProfilesError?: string
  cronofyConferencingProfiles?: CronofyConferencingProfiles[]
  bookingLoading?: boolean
  bookingSaving?: boolean
  bookingError?: string
  booking?: DetailedBooking
  lawyerMembershipTypesLoading?: boolean
  lawyerMembershipTypesSaving?: boolean
  lawyerMembershipTypesError?: string
  lawyerMembershipTypes?: {
    name: string
    id: string
  }[]
  lawyerQualificationTypesLoading?: boolean
  lawyerQualificationTypesSaving?: boolean
  lawyerQualificationTypesError?: string
  lawyerQualificationTypes?: {
    name: string
    id: string
  }[]
  detailedLawyerProfileLoading?: boolean
  detailedLawyerProfileSaving?: boolean
  detailedLawyerProfileError?: string
  detailedLawyerProfile?: DetailedLawyerProfile
  barristersLoading?: boolean
  barristersSaving?: boolean
  barristersError?: string
  barristers?: PageResult<BarristerProfile>
  barristerLoading?: boolean
  barristerSaving?: boolean
  barristerError?: string
  barrister?: BarristerDetail

  barristerHonoursLoading?: boolean
  barristerHonoursSaving?: boolean
  barristerHonoursError?: string
  barristerHonours?: Honour[]
  barristersSearchLoading?: boolean
  barristersSearchSaving?: boolean
  barristersSearchError?: string
  barristersSearch?: Barrister

  organisationBySraLoading?: boolean
  organisationBySraSaving?: boolean
  organisationBySraError?: string
  organisationBySra?: OrganisationDetail
  uploadAvatarLoading?: boolean
  uploadAvatarSaving?: boolean
  uploadAvatarError?: string
  uploadAvatar?: {
    [extraProps: string]: any
  }
  uploadUserAvatarLoading?: boolean
  uploadUserAvatarSaving?: boolean
  uploadUserAvatarError?: string
  uploadUserAvatar?: {
    [extraProps: string]: any
  }
  organisationLoading?: boolean
  organisationSaving?: boolean
  organisationError?: string
  organisation?: Record<string, Organisation>
  reportBarristerLoading?: boolean
  reportBarristerSaving?: boolean
  reportBarristerError?: string
  reportBarrister?: {
    [extraProps: string]: any
  }
  reportOrganisationLoading?: boolean
  reportOrganisationSaving?: boolean
  reportOrganisationError?: string
  reportOrganisation?: {
    [extraProps: string]: any
  }
  stripeSubscriptionsLoading?: boolean
  stripeSubscriptionsSaving?: boolean
  stripeSubscriptionsError?: string
  stripeSubscriptions?: StripeProduct[]
  stripeCheckoutLoading?: boolean
  stripeCheckoutSaving?: boolean
  stripeCheckoutError?: string
  stripeCheckout?: {
    [extraProps: string]: any
  }
  subscriptionLoading?: boolean
  subscriptionSaving?: boolean
  subscriptionError?: string
  subscription?: {
    [extraProps: string]: any
  }
  qualificationLoading?: boolean
  qualificationSaving?: boolean
  qualificationError?: string
  qualification?: {
    [extraProps: string]: any
  }
  membershipLoading?: boolean
  membershipSaving?: boolean
  membershipError?: string
  membership?: {
    [extraProps: string]: any
  }
  areaLoading?: boolean
  areaSaving?: boolean
  areaError?: string
  area?: {
    [extraProps: string]: any
  }

  // don't know if this has any use
  favouritesLoading?: boolean
  favouritesSaving?: boolean
  favouritesError?: string
  favourites?: {
    [extraProps: string]: any
  }

  profileDetailLoading?: boolean
  profileDetailError?: boolean
  profileDetail?: {
    [extraProps: string]: any
  }

  responseToReviewSaving?: boolean
  responseToReviewError?: string
  responseToReview?: {
    [extraProps: string]: any
  }
  leadershipArticleSaving?: boolean
  leadershipArticleError?: string
  leadershipArticle?: {
    [extraProps: string]: any
  }
  appointmentBookingLoading?: boolean
  appointmentBookingSaving?: boolean
  appointmentBookingError?: string
  appointmentBooking?: {
    [extraProps: string]: any
  }
  reviewLoading?: boolean
  reviewSaving?: boolean
  reviewError?: string
  review?: {
    [extraProps: string]: any
  }
  detailedLawfirmProfileLoading?: boolean

  detailedLawfirmProfileError?: string
  detailedLawfirmProfile?: {
    [extraProps: string]: any
  }
  detailedBarristerProfileLoading?: boolean
  detailedBarristerProfileError?: string
  detailedBarristerProfile?: {
    [extraProps: string]: any
  }
  bookingActionSaving?: boolean
  bookingActionError?: string
  bookingAction?: {
    [extraProps: string]: any
  }

  occupationsLoading?: boolean
  occupationsError?: string
  occupations?: {
    id: string
    name: string
  }[]

  // END OF STATE_TYPES
}

export interface RequestTypes {
  getLegalCheckerResults: {
    only_updated?: boolean
    identified?: boolean
  }
  legalCheckerScenarios: {
    subDomainId: string
  }
  legalCheckerQuestions: {
    subDomainId: string
  }
  profile: AppState['profile']
  feedbackGet: {
    url: string | null
  }
  membershipRedemptionGet: {
    url: string | null
  }
  login: {
    username: string
    password: string
    used_invite_token?: string
    account_type?: string
  }
  register: {
    email: string
    password: string
    first_name: string
    last_name: string
    account_type?: string
    used_invite_token?: string
    accepted_marketing: boolean
  }
  resetPassword: {
    email: string
  }
  logout: {
    id: string
  }
  oauth: {
    authorize: () => Promise<any>
    account_type?: string
    used_invite_token?: string
  }
  resendConfirmSignUp: {
    username: string
  }
  changePassword: {
    username: string
    code: string
    password: string
  }
  feedback: {
    score: number
    title: string
    positive_feedback: string
    improvement_feedback: string
    created_at: string
  }
  membershipRedemption: {
    code: string
  }
  legalCheckerGet: {
    url: string | null
  }
  legalCheckerSubDomains: {
    url: string
  }
  legalCheckerSubmission: {
    sample: string
  }
  legalCheckerServices: {
    page?: number
    page_size: number
    center: string
    radius_km: number
    scenarioId: string
  }
  legalCheckerPortals: {
    scenarioId: string
  }
  legalCheckerResources: {
    scenarioId: string
  }
  getLegalCheckerGuidance?: {
    roleId: string
    scenarioId: string
    resultId: string
  }
  getLegalCheckerFurtherGuidance?: {
    guidanceId: string
    resultId: string
  }
  getLegalCheckerResult?: {
    id: string
  }
  createLegalCheckerResult?: {
    sub_domain?: string
    role_description?: string
    status_description?: string
    successful_legal_aid_question?: string
    remedy_description?: string
    scenario?: string
    question_responses?: {
      question: string
      response: 'YES' | 'NO'
    }[]
    user_submission?: string
  }
  updateLegalCheckerResult?: {
    id: string
    sub_domain?: string
    role_description?: string
    status_description?: string
    question_responses?: {
      question: string
      response: 'YES' | 'NO'
    }[]
    successful_legal_aid_question?: string | null
    remedy_description?: string
    scenario?: string
    user_submission?: string
  }
  deleteLegalCheckerResult?: {
    id: string
  }
  getLegalAidQuestions?: {
    id: string
  }
  createGuidanceFeedback?: {
    id?: string
    guidance: string
    legal_checker_result: string
    score: number
  }
  updateGuidanceFeedback?: {
    id: string
    guidance: string
    legal_checker_result: string
    score: number
  }
  getLegalAidContent?: {
    [extraProps: string]: any
  }
  getVerifyCreditPurchase?: {
    [extraProps: string]: any
  }
  createVerifyCreditPurchase?: {
    platform: 'ios' | 'android' | 'macos' | 'windows' | 'web'
    receipt_payload: Purchase | Subscription
    isConsumable?: boolean
  }
  createClaimPurchase?: {
    platform: 'ios' | 'android' | 'macos' | 'windows' | 'web'
    receipt_payload: Purchase | Subscription
    is_subscription?: boolean
  }
  getIaps?: {
    accountType: AccountType
    creditType: CreditType
  }
  getSubscriptions?: {
    creditType: CreditType
  }
  getResultCredits?: {
    [extraProps: string]: any
  }
  getUnlock?: {
    [extraProps: string]: any
  }
  createUnlock?: {
    creditType: CreditType
    id: string
  }
  createViewResult?: {
    [extraProps: string]: any
  }
  getLegalDocuments?: {
    title?: string
    document_filters?: string | string[]
    url?: string
    paid?: boolean
    is_new?: boolean
    is_updated?: boolean
    is_template?: boolean
    sort: 'updated_at' | 'title' | 'created_at'
    order: 'ASC' | 'DESC'
    page?: number
    page_size?: number
    type?: string
  }
  getLegalDocument?: {
    id: string
  }
  getLegalDocumentCode?: {
    id: string
  }
  getLegalDocumentRatings?: {
    user_id?: string
    legaldocument_id?: string
  }
  createLegalDocumentRating?: {
    legaldocument: string
    rating: number
  }
  updateLegalDocumentRating?: {
    rating_id: string
    legaldocument: string
    rating: number
  }
  getLegalDocumentTypes?: {}
  getLegalDocumentTemplateFilters: {}
  getGovernmentServices?: {
    [extraProps: string]: any
  }
  createCloseAccount?: {}
  getCovidRegulations?: {
    url?: string
    title?: string
    domains?: string[]
    is_new?: boolean
    is_updated?: boolean
    sort: 'updated_at' | 'title'
    order: 'ASC' | 'DESC'
  }
  createLegalResultFeedback?: {
    result: string
    score: number
    title: string
    concern_feedback: string
    support_feedback: string
  }
  getIndustries?: {
    [extraProps: string]: any
  }
  getInviteToken?: {
    [extraProps: string]: any
  }
  getAreasOfPractice?: {
    category?: string
    exclude_areas_without_lawyers?: boolean
    featured?: boolean
  }
  getOffices?: {
    page?: number
    page_size?: number
    areas_of_practice?: string[]
    center?: string
    radius_km?: number
    scenario?: string
    min_price?: number
    max_price?: number
    premium?: boolean
    legal_aid_practitioner?: boolean
    membership_type?: MembershipType
    link?: string
    authorisation_period?: number
    favourites_only?: boolean
    sort_direction?: 'ASC' | 'DESC'
    sort_by?: SortBy
  }
  getOffice?: {
    id: string
  }
  createOffice?: {
    [extraProps: string]: any
  }
  updateOffice?: {
    [extraProps: string]: any
  }
  deleteOffice?: {
    [extraProps: string]: any
  }
  getAreaCategories?: {
    [extraProps: string]: any
  }
  getDocumentCategories?: {
    [extraProps: string]: any
  }
  getDocumentFilters?: {
    [extraProps: string]: any
  }
  documentFilters?: {
    id: string
    name: string
  }[]
  getLawyerAvailability?: {
    profile_id: string
    on_date: string
    duration_minutes?: 15 | 30 | 45 | 60
    lawyerType?: AccountType
  }
  createChallengeName?: {
    challengeName: string
  }
  createPassword: {
    newPassword: string
  }
  getLawyersProfile?: {
    [extraProps: string]: any
  }
  createLawyersProfile?: Partial<LawyerProfile>
  updateLawyersProfile?: Partial<BarristerProfile | LawyerProfile>
  deleteLawyersProfile?: {
    [extraProps: string]: any
  }
  createCronofyAuthLink?: {
    redirect_uri: string
  }
  createAuthWithCronofy?: {
    code: string
    profile_id: string
    redirect_uri: string
  }
  createSetupMfa?: {
    id: string
    // [extraProps: string]: any;
  }
  createVerifyMfa?: {
    authCode: string
    setPreferredMFA?: boolean
  }
  createCronofyUiToken?: {
    permissions: [
      'agenda'?,
      'account_management'?,
      'availability'?,
      'managed_availability'?,
    ]
    origin: string
  }
  enableCommission?: {}
  disableCommission?: {}
  getLawyerProfiles?: {
    min_price?: number
    max_price?: number
    page_size?: number
    center?: string
    radius_km?: number
    area_of_practice?: string
    premium?: boolean
    favourites_only?: boolean
    link?: string
    organisation?: string
    office?: string
    page?: number
  }
  createConfirmSignin?: {
    authCode: string
  }
  getBookings?: {
    upcoming_only?: boolean
    link?: string
    isConsumer?: boolean
  }
  createBookings?: {
    [extraProps: string]: any
  }
  updateBookings?: {
    [extraProps: string]: any
  }
  deleteBookings?: {
    [extraProps: string]: any
  }
  getBookALawyer?: {
    [extraProps: string]: any
  }
  createBookALawyer?: BookALawyerRequest
  updateBookALawyer?: {
    [extraProps: string]: any
  }
  deleteBookALawyer?: {
    [extraProps: string]: any
  }
  setBookALawyerAppointment?: Partial<BookALawyerRequest>
  createZoomAuthLink?: {
    redirect_uri: string
    profile_id: string
  }
  getCompanies?: {
    q: string
  }
  getStripeProductDetails?: {
    id: string
  }
  getStripeCheckoutUrl?: {
    id: string
    success_url: string
    cancel_url: string
  }
  createActionAppointment?: {
    id: string
    action: 'decline' | 'accept' | 'cancel'
  }
  getCronofyConferencingProfiles?: {
    profile_id: string
  }
  getBooking?: {
    id: string
  }
  getLawyerMembershipTypes?: {
    [extraProps: string]: any
  }
  getLawyerQualificationTypes?: {
    [extraProps: string]: any
  }
  getDetailedLawyerProfile?: {
    id: string
  }
  getDetailedLawfirmProfile?: {
    [extraProps: string]: any
  }
  getDetailedBarristerProfile?: {
    [extraProps: string]: any
  }
  getBarristers?: {
    min_price?: number
    max_price?: number
    page_size?: number
    center?: string
    radius_km?: number
    area_of_practice?: string
    premium?: boolean
    favourites_only?: boolean
    link?: string
    page?: number
  }
  getBarrister?: {
    id: string
  }
  getBarristerHonours?: {
    [extraProps: string]: any
  }
  getBarristersSearch?: {
    forenames: string
    surnames: string
    date_of_call: string
  }
  createBarrister?: {
    [extraProps: string]: any
  }
  updateBarrister?: {
    id: string
    user: string
  }
  deleteBarrister?: {
    [extraProps: string]: any
  }
  getOrganisationBySra?: {
    id: string
  }
  createUploadAvatar?: {
    [extraProps: string]: any
  }
  createUploadUserAvatar?: {
    file: File
    width?: number
    height?: number
    x?: number
    y?: number
  }
  getOrganisation?: {
    id: string
  }
  createOrganisation?: {
    [extraProps: string]: any
  }
  updateOrganisation?: {
    [extraProps: string]: any
  }
  deleteOrganisation?: {
    [extraProps: string]: any
  }
  createReportBarrister?: Partial<BarristerProfile>
  createReportOrganisation?: Partial<Organisation>
  updateBooking?: Partial<DetailedBooking>
  getStripeSubscriptions?: {}
  createStripeCheckout?: {
    product_ids: string[]
    coupon_ids: string[]
  }
  getSubscription?: {
    [extraProps: string]: any
  }
  createSubscription?: {
    [extraProps: string]: any
  }
  updateSubscription?: {
    [extraProps: string]: any
  }
  deleteSubscription?: {
    [extraProps: string]: any
  }
  createQualification?: {
    name: string
  }
  createMembership?: {
    name: string
  }
  createArea?: {
    name: string
  }
  createFavourite?: {
    [extraProps: string]: any
  }
  deleteFavourite?: {
    [extraProps: string]: any
  }
  getProfileDetail?: {
    [extraProps: string]: any
  }
  createResponseToReview?: {
    [extraProps: string]: any
  }
  createLeadershipArticle?: any
  createAppointmentBooking?: CreateAppointmentBookingRequest
  createReview?: {
    [extraProps: string]: any
  }
  createBookingAction?: {
    id: string
    response: 'accept' | 'decline'
  }
  getAcceptedBookings?: {
    link?: string
  }
  getPendingBookings?: {
    link?: string
  }
  getDeclinedBookings?: {
    link?: string
  }
  getLawyerBookings?: {
    [extraProps: string]: any
  }
  getBarristerBookings?: {
    [extraProps: string]: any
  }
  getOccupations?: {
    [extraProps: string]: any
  }
  // END OF REQUEST_TYPES
}
