import { SearchIcon } from '@heroicons/react/solid'
import SearchField from 'components/search/SearchField'
import React, { useState } from 'react'

export enum ComponentType {
  DOCUMENT = 'DOCUMENT',
  LAW = 'LAW',
}

interface HomeSearchBarProps {
  type: keyof typeof ComponentType
}

const HomeSearchBar: React.FC<HomeSearchBarProps> = ({ type }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearch = (e) => {
    e.preventDefault()
    if (type === ComponentType.DOCUMENT) {
      console.log('Searching for document:', searchTerm)
    } else if (type === ComponentType.LAW) {
      console.log('Searching for law:', searchTerm)
    }
  }

  const getTitle = () => {
    return type === ComponentType.DOCUMENT
      ? 'Document Dictionary'
      : 'Law finder'
  }

  const getSubtitle = () => {
    return type === ComponentType.DOCUMENT
      ? 'Type in the name of a legal document to get its purpose.'
      : 'Type in an explanation to get help finding the correct area of law.'
  }

  const getPlaceholder = () => {
    return type === ComponentType.DOCUMENT
      ? "Legal document's name"
      : 'Type in your need...'
  }

  return (
    <div className='w-full max-w-2xl mx-auto px-2'>
      <div className='text-center mb-6'>
        <h2 className='text-3xl font-bold text-[#1E3A5F] mb-2'>{getTitle()}</h2>
        <p className='text-sm text-[#405985]'>{getSubtitle()}</p>
      </div>
      <form onSubmit={handleSearch} className='relative'>
        <SearchField
          placeholder={getPlaceholder()}
          onSearch={handleSearch}
          icon={<SearchIcon className='w-5 h-5 text-[#122D5C]' />}
          error={false} // Set to true to show error state
          disabled={false}
        />
        <button
          type='submit'
          className='absolute right-3 top-1/2 transform -translate-y-1/2'
        ></button>
      </form>
    </div>
  )
}

export { HomeSearchBar }
