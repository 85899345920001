import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../common/components/Authentication/Button'
import { LawyerProfileCard } from '../../CWP Sections/lawyer/LawyerProfileCard'
import { LawfirmProfileCard } from '../../CWP Sections/lawfirm/LawfirmProfileCard'
import {
  BarristerProfileCard,
  CardMode,
} from '../../CWP Sections/barrister/BarristerProfileCard'

import legalFieldsBg1 from '../../../assets/legal_fields_bg_1.svg'
import legalFieldsBg2 from '../../../assets/legal_fields_bg_2.svg'
import legalFieldsBg4 from '../../../assets/legal_fields_bg_4.svg'
import legalFieldsBgSm1 from '../../../assets/legal_fields_bg_sm_1.svg'
import confidenceIcon from '../../../assets/confidence.svg'
import checkIcon from '../../../assets/check.svg'
import optionIcon from '../../../assets/options.svg'
import thumbnail from '../../../assets/card-thumbnail.svg'
import legalFieldsBgSm3 from '../../../assets/legal_fields_bg_sm_3.svg'
import legalFieldsBgSm from '../../../assets/legal_fields_sm.svg'
import legalFieldsBg from '../../../assets/legal_fields_bg.svg'
import { SiteFooterSocialLinks } from '../../SiteFooterSocialLinks'
import { InterviewsSection } from '../Discover Law/InterviewSection'
import VideoPlayer from '../../../components/Multimedia/VideoPlayer'
import { useVideoPlayer } from 'hooks/useVideoPlayer'
import { EpisodeList, dummyEpisodes } from '../Discover Law/PodcastSection'
import {
  AccountType,
  BarristerProfile,
  LawyerListObject,
  OfficeSummary,
} from '../../../../../common/state-type'

import useOffices from '../../../common/providers/useOffices'
import useFavourite from '../../../common/providers/useFavourite'
import useBarristers from '../../../common/providers/useBarristers'
import useLawyerProfiles from '../../../common/providers/useLawyerProfiles'
import { all } from 'redux-saga/effects'

// Dynamic data
const pageData = {
  title: 'Legal fields',
  subtitle:
    'Dive into this legal field and discover its many specialised branches.',
  legalSubFields: [
    'Commercial Law',
    'Family Law',
    'Criminal Law',
    'Intellectual Property Law',
  ],
  mediaCards: [
    {
      thumbnail: thumbnail,
      date: '7th Jan, 22',
      title: 'Understanding Contract Law',
      description:
        'An in-depth look at the basics of contract law and its implications in business.',
      tags: ['Commercial', 'Contracts', 'Business Law'],
      author: 'Richard Cammin',
      authorRole: 'Solicitor',
      authorImage: thumbnail,
    },
    {
      thumbnail: thumbnail,
      date: '15th Feb, 22',
      title: 'Navigating Divorce Proceedings',
      description: 'A guide to the legal aspects of divorce and separation.',
      tags: ['Family Law', 'Divorce', 'Legal Process'],
      author: 'Sarah Johnson',
      authorRole: 'Family Lawyer',
      authorImage: thumbnail,
    },
    {
      thumbnail: thumbnail,
      date: '3rd Mar, 22',
      title: 'Intellectual Property Rights',
      description:
        'Protecting your ideas: An overview of patents, trademarks, and copyrights.',
      tags: ['IP Law', 'Patents', 'Trademarks'],
      author: 'Michael Lee',
      authorRole: 'IP Specialist',
      authorImage: thumbnail,
    },
    {
      thumbnail: thumbnail,
      date: '22nd Apr, 22',
      title: 'Criminal Defense Strategies',
      description: 'Exploring effective legal defenses in criminal cases.',
      tags: ['Criminal Law', 'Legal Defense', 'Court Proceedings'],
      author: 'Emily Davis',
      authorRole: 'Criminal Defense Attorney',
      authorImage: thumbnail,
    },
  ],
  videoData: {
    src: 'https://www.youtube.com/embed/dHqg2urvK1s?enablejsapi=1',
    title: 'Interview with John Doe',
    interviewee: 'John Doe',
  },
  approachCards: [
    { icon: confidenceIcon, title: 'Build your confidence' },
    { icon: optionIcon, title: 'Discover your options' },
    { icon: checkIcon, title: 'Choose what works for you' },
  ],
}

export const LegalFieldsPage = () => {
  const { videoRef } = useVideoPlayer()
  const navigate = useNavigate()

  const { createFavourite, deleteFavourite } = useFavourite()
  const { getOffices, offices: _offices } = useOffices()
  const { getBarristers, barristers: _barristers } = useBarristers()
  const { getLawyerProfiles, lawyerProfiles: _lawyers } = useLawyerProfiles()
  const [bookingProfile, setBookingProfile] = useState<any>(null)

  const handleFavPress = (item: any, accountType: AccountType) => {
    // Implement favorite functionality
  }

  const handleBookPress = (item, accountType) => {
    setBookingProfile({ profile: item, lawyerType: accountType })
  }

  const fetchParams = {
    page_size: 20,
    favourites_only: true,
  }

  useEffect(() => {
    getLawyerProfiles(fetchParams)
    getBarristers(fetchParams)
    getOffices(fetchParams)
  }, [])

  const solicitor = _lawyers?.results?.map((lawyer) => ({
    ...lawyer,
    type: AccountType.LAWYER,
  }))

  const barristers = _barristers?.results?.map((barrister) => ({
    ...barrister,
    type: AccountType.BARRISTER,
  }))

  const lawfirms = _offices?.results?.map((office) => ({
    ...office,
    type: AccountType.BUSINESS,
  }))

  const allProfiles =
    solicitor && barristers && lawfirms
      ? [...solicitor, ...barristers, ...lawfirms]
      : []

  console.log(allProfiles)
  return (
    <div className='bg-white min-h-screen'>
      <section
        className='pt-4 sm:py-6 md:py-8 lg:py-10 px-[4%] sm:px-[0%] md:px-[2%] lg:px-[2%] xl:px-[4%] mb-[2%] relative'
        style={{
          width: '100%',
          minHeight: window.innerWidth < 1024 ? '520px' : 'auto',
          overflow: 'hidden',
        }}
      >
        <div
          className='absolute z-0  inset-0'
          style={{
            backgroundImage: `url(${
              window.innerWidth < 1024 ? legalFieldsBgSm : legalFieldsBg
            })`,

            backgroundSize: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className='relative z-10 mx-auto flex py-4 md:py-0 flex-col md:flex-row items-start gap-4 '>
          <div className='flex flex-col md:flex-row justify-between items-center w-full'>
            <div className='w-full md:w-[50%] flex flex-col justify-center'>
              <h1 className='text-[#122D5C] text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold text-blue-900 mb-2'>
                {pageData.title}
              </h1>
              <h3 className='text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl font-bold text-[#101828] mb-4 leading-tight'>
                {pageData.subtitle}
              </h3>
              <div className='transparent mt-10 inline-flex'>
                <SiteFooterSocialLinks
                  variant='small'
                  isDark
                  className='flex space-x-4'
                />
              </div>
            </div>
          </div>
          <div className='w-full md:w-[40%] aspect-[15/8] pt-4 pb-2 lg:pt-0 lg:pb-0 flex items-center mr-[2%]'>
            <div className='bg-[#101828] w-full h-full rounded-lg flex items-center justify-center text-white'>
              Image
            </div>
          </div>
        </div>
      </section>

      <FilterSelector legalSubFields={pageData.legalSubFields} />
      <main className='container mx-auto  py-8 mt-12'>
        <section className='relative pb-[4%] '>
          <div
            className='w-full '
            style={{
              backgroundImage:
                window.innerWidth > 640 ? `url(${legalFieldsBg1})` : 'none',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='relative z-10'>
              <div
                className='my-12 '
                style={{
                  backgroundImage:
                    window.innerWidth > 640 ? `url(${legalFieldsBg4})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <h1 className='text-2xl sm:text-3xl md:text-4xl font-semibold ml-[4%] mb-[2%] transform -translate-y-[2%]'>
                  Latest media
                </h1>
                <div
                  className={
                    'p-4 rounded-xl relative z-1000 mx-auto w-[99%] sm:w-[94%] bg-[#FDFEFF] bg-opacity-40 backdrop-filter backdrop-blur-lg'
                  }
                >
                  <div className='p-4'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-16'>
                      {pageData.mediaCards.map((card, index) => (
                        <MediaCard key={index} {...card} />
                      ))}
                    </div>
                  </div>

                  <InterviewsSection />
                  <div className='my-[4%]'>
                    <VideoPlayer
                      ref={videoRef}
                      src={pageData.videoData.src}
                      isYouTube
                      title={pageData.videoData.title}
                      interviewee={pageData.videoData.interviewee}
                    />
                  </div>

                  <section className='mb-8 mt-16 mx-[4%]'>
                    <h3 className='text-xl sm:text-2xl font-semibold mb-4'>
                      Podcasts
                    </h3>
                    <div className='container mx-auto max-w-7xl my-12'>
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-start'>
                        <EpisodeList episodes={dummyEpisodes} />
                      </div>

                      {dummyEpisodes.length > 3 && (
                        <div className='flex justify-end mt-4'>
                          <Button
                            variant='quaternary'
                            className='text-end underline !w-full sm:!w-[20%]'
                            onClick={() => {}}
                          >
                            See more
                          </Button>
                        </div>
                      )}
                    </div>
                    <Button
                      variant='quaternary'
                      className='text-center mt-8 w-full sm:w-auto underline'
                      onClick={() => {}}
                    >
                      Find a lawyer near you
                    </Button>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className='p-6 mb-8 relative w-full'
          style={{
            backgroundImage: `url(${legalFieldsBg2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div
            className='absolute inset-0 z-0 w-full sm:hidden'
            style={{
              backgroundColor: 'white',
            }}
          ></div>
          <div
            className='absolute inset-0 z-0 w-full hidden sm:block'
            style={{
              backgroundImage: `url(${legalFieldsBg2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <div className='relative z-10 mx-auto w-[80%] '>
            <div className='text-2xl sm:text-3xl font-semibold mb-4 text-center  mx-auto w-[80%] '>
              Our approach is your solution
            </div>

            <div className='text-sm sm:text-md font-normal text-[#818285] mb-4 leading-6 tracking-[0.4px]  mx-auto w-[80%] '>
              Getting legal help has now changed. You can get access to legal
              information yourself, create legal documents yourself, and locate
              the right lawyer based on your precise needs. Our approach is to
              give you the confidence, choice, and control over the solution you
              want to solve your legal issue.
            </div>

            <CardSection cards={pageData.approachCards} />
          </div>
        </section>

        <section className='py-8 px-4 bg-white rounded-lg shadow-lg'>
          <div className='text-2xl sm:text-3xl font-semibold mb-4'>
            Get advice today
          </div>
          <div className='text-sm sm:text-md text-[#818285] mb-4'>
            Connect with experienced lawyers now and get the expert advice you
            need.
          </div>
          <div className='flex flex-wrap gap-4'>
            {allProfiles.map((profile) => {
              switch (profile.type) {
                case AccountType.LAWYER:
                  return (
                    <LawyerProfileCard
                      mode={CardMode.FAVOURITE}
                      className='hidden md:flex'
                      key={profile.id}
                      data={profile as LawyerListObject}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.LAWYER)
                      }
                    />
                  )
                case AccountType.BARRISTER:
                  return (
                    <BarristerProfileCard
                      mode={CardMode.FAVOURITE}
                      className='hidden md:flex flex-1'
                      data={profile as BarristerProfile}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.BARRISTER)
                      }
                      onBookNow={() =>
                        handleBookPress(
                          profile as BarristerProfile,
                          AccountType.BARRISTER,
                        )
                      }
                    />
                  )
                case AccountType.BUSINESS:
                  return (
                    <LawfirmProfileCard
                      cardStyle={'mt-4'}
                      mode={CardMode.FAVOURITE}
                      key={profile.id}
                      data={profile as OfficeSummary}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.BUSINESS)
                      }
                      onBookNow={() =>
                        navigate(`/lawfirms/${profile.id}/members`)
                      }
                    />
                  )
                default:
                  return null
              }
            })}
          </div>
        </section>

        <div className='flex flex-col items-center justify-center mt-[10%] py-12 px-4'>
          <h2 className='text-xl sm:text-2xl font-semibold text-gray-800 mb-2 text-center'>
            Seeking legal help?
          </h2>
          <p className='text-lg sm:text-lg text-gray-800 mb-6 text-center'>
            Find an expert lawyer today.
          </p>
          <Button className={'mx-auto w-full sm:!w-[20%] md:!w-[10%]'}>
            Find
          </Button>
        </div>
      </main>
    </div>
  )
}

const MediaCard = ({
  thumbnail,
  date,
  title,
  description,
  tags,
  author,
  authorRole,
  authorImage,
}) => (
  <div className='bg-white rounded-lg shadow-md overflow-hidden'>
    <div className='relative'>
      <img
        src={thumbnail}
        alt='Thumbnail'
        className='w-full h-40 object-cover'
      />
      <div className='absolute top-2 left-2 bg-[#D2F6FF] px-2 py-1 rounded-full text-xs font-medium text-[#101828]'>
        {date}
      </div>
    </div>
    <div className='p-4'>
      <h3 className='font-medium text-base sm:text-lg mb-1 text-[#101828] line-clamp-2'>
        {title}
      </h3>
      <p className='text-xs sm:text-sm text-[#6E7785] mb-3 line-clamp-2'>
        {description}
      </p>
      <div className='flex flex-wrap gap-2 mb-3'>
        {tags.map((tag, index) => (
          <span
            key={index}
            className={`text-xs font-medium px-2 py-1 rounded ${
              index % 2 === 0 ? 'bg-[#EBF3FF]' : 'bg-[#D2F6FF]'
            } text-[#101828]`}
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
    <div className='px-2 pb-1 flex items-center justify-end'>
      <div className='bg-[#D2F6FF] rounded-full px-3 py-1 mb-1'>
        <div className='flex items-center justify-start'>
          <img
            src={authorImage}
            alt={author}
            className='w-6 h-6 rounded-full mr-2'
          />
          <div className='flex flex-col items-start justify-start'>
            <span className='text-xs text-[#6E7785]'>{author}</span>
            <span className='text-xs text-[#6E7785]'>{authorRole}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const CardSection = ({ cards }) => (
  <div className='container mx-auto px-4 py-16 w-full'>
    <div className='flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12 lg:space-x-16'>
      {cards.map((card, index) => (
        <div
          key={index}
          className='bg-white rounded-3xl shadow-lg px-8 py-8 w-full md:w-[30%] h-64 flex flex-col items-center justify-center text-center'
        >
          <img
            src={card.icon}
            alt={card.title}
            className='w-12 h-12 sm:w-16 sm:h-16 mb-6'
          />
          <h3 className='text-xl sm:text-2xl font-semibold'>{card.title}</h3>
        </div>
      ))}
    </div>
  </div>
)

const FilterSelector = ({ legalSubFields }) => {
  const [selectedField, setSelectedField] = useState(null)

  return (
    <div className='flex flex-wrap gap-2 sm:gap-4 py-4 pr-[5%] justify-end '>
      {legalSubFields.map((field, index) => (
        <button
          key={index}
          className={`mb-2 sm:mb-0 px-2 sm:px-4 py-1 sm:py-2 rounded-lg shadow-[0_1px_4px_rgba(0,0,0,0.25)] text-xs sm:text-sm font-medium transition-colors duration-200 ease-in-out
                 ${
                   selectedField === field
                     ? 'bg-[#1C7D93] text-white'
                     : 'bg-white text-[#1C7D93] '
                 }`}
          onClick={() => setSelectedField(field)}
        >
          {field}
        </button>
      ))}
    </div>
  )
}
