import React from 'react'
import { Button } from '../../../../../common/components/Authentication/Button'
import VideoPlayer from '../../Multimedia/VideoPlayer'
import { useVideoPlayer } from '../../../hooks/useVideoPlayer'

export const LifeThroughLegalLensSection = () => {
  const { videoRef } = useVideoPlayer()

  return (
    <section className='mb-12 mt-16'>
      <h2 className='text-2xl font-semibold py-12'>
        Life through the legal lens
      </h2>
      <div className='flex flex-col sm:flex-row gap-12'>
        <div className='w-full sm:w-1/2'>
          <VideoPlayer
            ref={videoRef}
            src='https://www.youtube.com/embed/dHqg2urvK1s?enablejsapi=1'
            isYouTube
            title='Interview with John Doe'
            interviewee='John Doe'
          />
        </div>
        <div className='w-full sm:w-1/2 flex flex-col justify-center mt-8 sm:mt-0'>
          <div>
            <h3 className='text-xl font-semibold mb-4'>Section explanation</h3>
            <p className='text-[#6E7785] mb-4'>
              Here's a wealth of legal knowledge for diving into our extensive
              collection of blogs and articles, authored by top legal
              professionals. Start exploring now to discover valuable insights
              on your legal needs.
            </p>
            <Button
              variant='secondary'
              className='text-left mt-8 w-full sm:w-[20%] !py-1 !px-0 shadow-[0_1px_4px_rgba(0,0,0,0.25)]'
              onClick={() => {}}
            >
              Show more
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
