import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import React, { useEffect, useState } from 'react'
import { MembershipType, MemberStatus } from '../../../../../common/state-type'

import {
  Booking,
  BookingStatus,
  BarristerProfile,
  AccountType,
} from 'common/state-type'
import { getCardTheme } from 'utils/StylesUtil'
import AppointmentDetail from './AppointmentDetail'
import useLawyerBookings from 'common/providers/useLawyerBookings'
import useBarristerBookings from 'common/providers/useBarristerBookings'
import useFavourite from 'common/providers/useFavourite'
import { LawyerProfileCard } from '../lawyer/LawyerProfileCard'

import {
  BarristerProfileCard,
  CardMode,
} from '../barrister/BarristerProfileCard'

const dummyBookings: Booking[] = [
  {
    id: 'booking123',
    barrister: {
      id: 'barrister456',
      favourite_id: 'fav789',
      address_line_1: '123 Barrister St',
      address_line_2: 'Suite 4',
      areas_of_practice: ['Criminal Law', 'Family Law'],

      avatar: 'https://example.com/avatar.jpg',
      average_rating: 4.8,
      bio: 'Experienced barrister specializing in criminal and family law',
      chamber_name: 'Justice Chambers',
      county: 'London',
      date_of_call: '2010-05-15',
      email_address: 'john.doe@example.com',
      full_name: 'John Doe',
      google_business_url: 'https://g.page/johndoebarrister',
      hourly_rate: 250,
      including_vat: 300,
      start_practing_on: '2010-06-01',
      latitude: 51.5074,
      longitude: -0.1278,
      member_status: MemberStatus.DOOR_TENANT,
      membership_type: MembershipType.NONE,
      rate_per_hour_excluding_vat: 250,
      rate_per_hour_including_vat: 300,

      phone_number: '+44 20 1234 5678',
      postal_code: 'SW1A 1AA',

      public_access: true,
      qualifications: [
        {
          id: 'qual1',
          name: 'Bar Professional Training Course',
        },
      ],
      memberships: [{ id: 'mem1', name: 'Bar Council' }],
      sra_authorised: true,
      title: 'Mr',
      town: 'London',
      website_url: 'https://johndoebarrister.com',
      pays_commission: true,
    },
    lawyer: null,
    start: new Date('2024-09-20T10:00:00Z'),
    end: new Date('2024-09-20T11:00:00Z'),
    is_video: true,
    notes: 'Discussing criminal law case',
    accessibility_requirements: 'Sign language interpreter needed',
    status: BookingStatus.ACCEPTED,
    identification_document: 'https://example.com/id_document.pdf',
    proof_of_address: 'https://example.com/proof_of_address.pdf',
    companies_house_company_number: '12345678',
    user: {
      id: 'user789',
      first_name: 'Jane',
      last_name: 'Smith',
      email: 'jane.smith@example.com',
    },
    guests: ['guest1@example.com', 'guest2@example.com'],
    rating_id: 'rating101',
  },
]

const bookings = [
  {
    id: 'booking123',
    barrister: {
      id: 'barrister456',
      favourite_id: 'fav789',
      address_line_1: '123 Barrister St',
      address_line_2: 'Suite 4',
      areas_of_practice: ['Criminal Law', 'Family Law'],
      authenticated_with_cronofy: 'yes',
      avatar: 'https://example.com/avatar.jpg',
      average_rating: 4.8,
      bio: 'Experienced barrister specializing in criminal and family law',
      chamber_name: 'Justice Chambers',
      county: 'London',
      date_of_call: '2010-05-15',
      email_address: 'john.doe@example.com',
      full_name: 'John Doe',
      google_business_url: 'https://g.page/johndoebarrister',
      hourly_rate: 250,
      including_vat: 300,
      start_practing_on: '2010-06-01',
      latitude: 51.5074,
      longitude: -0.1278,
      member_status: MemberStatus.DOOR_TENANT,
      membership_type: MembershipType.NONE,
      rate_per_hour_excluding_vat: 250,
      rate_per_hour_including_vat: 300,
      number_of_practice_years: '14',
      phone_number: '+44 20 1234 5678',
      postal_code: 'SW1A 1AA',
      primary_status: 'EMPLOYED',
      public_access: true,
      qualifications: [
        {
          id: 'qual1',
          name: 'Bar Professional Training Course',
        },
      ],
      memberships: [{ id: 'mem1', name: 'Bar Council' }],
      sra_authorised: true,
      title: 'Mr',
      town: 'London',
      website_url: 'https://johndoebarrister.com',
      pays_commission: true,
    },
    lawyer: {
      id: 'lawyer789',
      bio: 'Experienced lawyer specializing in corporate and intellectual property law',
      avatar: 'https://example.com/lawyer_avatar.jpg',
      user: {
        id: 'user456',
        first_name: 'Jane',
        last_name: 'Doe',
        email: 'jane.doe@example.com',
      },
      organisation: {
        id: 'org123',
        name: 'Doe & Associates',
      },
      office: {
        id: 'office456',
        address_line_1: '456 Lawyer Ave',
        address_line_2: 'Floor 10',
        city: 'London',
        postal_code: 'EC1A 1BB',
      },
      linkedin_profile_url: 'https://www.linkedin.com/in/janedoelawyer',
      google_business_url: 'https://g.page/janedoelawyer',
      trust_pilot_reviews_url:
        'https://www.trustpilot.com/review/janedoelawyer.com',
      google_reviews_url: 'https://g.page/janedoelawyer/reviews',
      twitter_profile_url: 'https://twitter.com/janedoelawyer',
      is_partner: true,
      title: 'Ms',
      average_rating: 4.9,
      sra_authorised: true,
      qualifications: [
        {
          id: 'qual2',
          name: 'Legal Practice Course',
        },
        {
          id: 'qual3',
          name: 'LLM in Intellectual Property Law',
        },
      ],
      memberships: [
        { id: 'mem2', name: 'Law Society' },
        { id: 'mem3', name: 'Intellectual Property Lawyers Association' },
      ],
      sra_number: 'SRA123456',
      website_url: 'https://janedoelawyer.com',
      phone_number: '+44 20 9876 5432',
      number_of_practice_years: 12,
      rate_per_hour_excluding_vat: 300,
      rate_per_hour_including_vat: 360,
      favourite_id: 'fav101',
      membership_type: MembershipType.PREMIUM,
    },
    start: new Date('2024-09-20T10:00:00Z'),
    end: new Date('2024-09-20T11:00:00Z'),
    is_video: true,
    notes: 'Discussing criminal law case',
    accessibility_requirements: 'Sign language interpreter needed',
    status: BookingStatus.ACCEPTED,
    identification_document: 'https://example.com/id_document.pdf',
    proof_of_address: 'https://example.com/proof_of_address.pdf',
    companies_house_company_number: '12345678',
    user: {
      id: 'user789',
      first_name: 'Jane',
      last_name: 'Smith',
      email: 'jane.smith@example.com',
    },
    guests: ['guest1@example.com', 'guest2@example.com'],
    rating_id: 'rating101',
  },
  {
    id: 'booking123',
    barrister: {
      id: 'barrister456',
      favourite_id: 'fav789',
      address_line_1: '123 Barrister St',
      address_line_2: 'Suite 4',
      areas_of_practice: ['Criminal Law', 'Family Law'],
      authenticated_with_cronofy: 'yes',
      avatar: 'https://example.com/avatar.jpg',
      average_rating: 4.8,
      bio: 'Experienced barrister specializing in criminal and family law',
      chamber_name: 'Justice Chambers',
      county: 'London',
      date_of_call: '2010-05-15',
      email_address: 'john.doe@example.com',
      full_name: 'Doe John',
      google_business_url: 'https://g.page/johndoebarrister',
      hourly_rate: 250,
      including_vat: 300,
      start_practing_on: '2010-06-01',
      latitude: 51.5074,
      longitude: -0.1278,
      member_status: MemberStatus.DOOR_TENANT,
      membership_type: MembershipType.NONE,
      rate_per_hour_excluding_vat: 250,
      rate_per_hour_including_vat: 300,
      number_of_practice_years: '14',
      phone_number: '+44 20 1234 5678',
      postal_code: 'SW1A 1AA',
      primary_status: 'EMPLOYED',
      public_access: true,
      qualifications: [
        {
          id: 'qual1',
          name: 'Bar Professional Training Course',
        },
      ],
      memberships: [{ id: 'mem1', name: 'Bar Council' }],
      sra_authorised: true,
      title: 'Mr',
      town: 'London',
      website_url: 'https://johndoebarrister.com',
      pays_commission: true,
    },
    lawyer: {
      id: 'lawyer789',
      bio: 'Experienced lawyer specializing in corporate and intellectual property law',
      avatar: 'https://example.com/lawyer_avatar.jpg',
      user: {
        id: 'user456',
        first_name: 'Jane',
        last_name: 'Doe',
        email: 'jane.doe@example.com',
      },
      organisation: {
        id: 'org123',
        name: 'Doe & Associates',
      },
      office: {
        id: 'office456',
        address_line_1: '456 Lawyer Ave',
        address_line_2: 'Floor 10',
        city: 'London',
        postal_code: 'EC1A 1BB',
      },
      linkedin_profile_url: 'https://www.linkedin.com/in/janedoelawyer',
      google_business_url: 'https://g.page/janedoelawyer',
      trust_pilot_reviews_url:
        'https://www.trustpilot.com/review/janedoelawyer.com',
      google_reviews_url: 'https://g.page/janedoelawyer/reviews',
      twitter_profile_url: 'https://twitter.com/janedoelawyer',
      is_partner: true,
      title: 'Ms',
      average_rating: 4.9,
      sra_authorised: true,
      qualifications: [
        {
          id: 'qual2',
          name: 'Legal Practice Course',
        },
        {
          id: 'qual3',
          name: 'LLM in Intellectual Property Law',
        },
      ],
      memberships: [
        { id: 'mem2', name: 'Law Society' },
        { id: 'mem3', name: 'Intellectual Property Lawyers Association' },
      ],
      sra_number: 'SRA123456',
      website_url: 'https://janedoelawyer.com',
      phone_number: '+44 20 9876 5432',
      number_of_practice_years: 12,
      rate_per_hour_excluding_vat: 300,
      rate_per_hour_including_vat: 360,
      favourite_id: 'fav101',
      membership_type: MembershipType.PREMIUM,
    },
    start: new Date('2024-09-20T10:00:00Z'),
    end: new Date('2024-09-20T11:00:00Z'),
    is_video: true,
    notes: 'Discussing criminal law case',
    accessibility_requirements: 'Sign language interpreter needed',
    status: BookingStatus.ACCEPTED,
    identification_document: 'https://example.com/id_document.pdf',
    proof_of_address: 'https://example.com/proof_of_address.pdf',
    companies_house_company_number: '12345678',
    user: {
      id: 'user789',
      first_name: 'Jane',
      last_name: 'Smith',
      email: 'jane.smith@example.com',
    },
    guests: ['guest1@example.com', 'guest2@example.com'],
    rating_id: 'rating101',
  },
]

const Appointments = () => {
  const navigate = useNavigate()

  const { createFavourite, deleteFavourite } = useFavourite()
  const { getLawyerBookings, lawyerBookings } = useLawyerBookings()
  const { getBarristerBookings, barristerBookings } = useBarristerBookings()
  const [isVisible, setIsVisible] = useState(false)
  const [sortBy, setSortBy] = useState('date')
  const [sortedBookings, setSortedBookings] = useState([])

  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(
    dummyBookings[0],
  )

  useEffect(() => {
    getLawyerBookings({})
    getBarristerBookings({})
    setSortedBookings(bookings)
  }, [])

  const getStatusBackgroundColor = (status: BookingStatus) => {
    switch (status) {
      case BookingStatus.PENDING_BARRISTER_ACCEPTANCE:
      case BookingStatus.PENDING_LAWYER_ACCEPTANCE:
        return 'bg-[#F3EB1B]'
      case BookingStatus.ACCEPTED:
        return 'bg-[#6BAC7A]'
      case BookingStatus.CANCELLED:
      case BookingStatus.DECLINED:
        return 'bg-[#DD2720]'
      case BookingStatus.EXPIRED:
        return 'bg-[#9d9fa1]'
      case BookingStatus.COMPLETE:
        return 'bg-[#1976D2]'
      default:
        return 'bg-[#F3EB1B]'
    }
  }
  const getStatus = (status: BookingStatus) => {
    switch (status) {
      case BookingStatus.PENDING_BARRISTER_ACCEPTANCE:
      case BookingStatus.PENDING_LAWYER_ACCEPTANCE:
        return 'Pending'
      case BookingStatus.ACCEPTED:
        return 'Meeting Confirmed'
      case BookingStatus.CANCELLED:
      case BookingStatus.DECLINED:
        return 'Cancelled'
      case BookingStatus.EXPIRED:
        return 'Expired'
      case BookingStatus.COMPLETE:
        return 'Meeting Completed'
      default:
        return 'Pending'
    }
  }

  const bookingTheme =
    selectedBooking && selectedBooking.barrister
      ? getCardTheme(selectedBooking.barrister.membership_type)
      : selectedBooking?.lawyer
      ? getCardTheme(selectedBooking?.lawyer?.membership_type)
      : null

  const handleCardClick = (item: any) => {
    setIsVisible(true)
    setSelectedBooking(item)
  }

  const handleSort = (sortType) => {
    setSortBy(sortType)
    if (sortType === 'name') {
      setSortedBookings(
        [...bookings].sort((a, b) => {
          const aName = a.lawyer
            ? a.lawyer.user.first_name
            : a.barrister.full_name
          const bName = b.lawyer
            ? b.lawyer.user.first_name
            : b.barrister.full_name
          return bName.localeCompare(aName)
        }),
      )
    } else {
      setSortedBookings(
        [...bookings].sort((a, b) => {
          const aStart = new Date(a.start).getTime()
          const bStart = new Date(b.start).getTime()
          return aStart - bStart
        }),
      )
    }
  }

  // lawyerBookings && barristerBookings
  //   ? [...lawyerBookings.results, ...barristerBookings.results]
  //   : []
  return (
    <>
      <div
        className='h-16 flex items-center justify-center'
        style={{
          background:
            'linear-gradient(90deg, #CCE0DD 0%, #9FB7D7 50%, rgba(190, 190, 223, 0.98) 100%)',
        }}
      >
        <h1 className='text-white text-2xl font-bold'>Your appointments</h1>
      </div>

      <div className='max-w-7xl mx-auto'>
        <div className='flex justify-between items-center px-4 md:px-12 mt-8  '>
          <div className='relative inline-block text-left'>
            <select
              className='block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline'
              value={sortBy}
              onChange={(e) => handleSort(e.target.value)}
            >
              <option value='date'>Sort by date</option>
              <option value='name'>Sort by name</option>
            </select>
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
              <svg
                className='fill-current h-4 w-4'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
              </svg>
            </div>
          </div>
          <span className='text-gray-600'>
            {sortedBookings.length} appointments
          </span>
        </div>

        <div className='flex flex-col md:flex-row items-center'>
          <div
            className='flex-1 overflow-y-auto'
            style={{ maxHeight: 'calc(100vh - 60px)' }}
          >
            <div className='p-4 md:p-12'>
              <div className='flex flex-col md:flex-row md:flex-wrap md:justify-start items-start space-y-6 md:space-y-0'>
                {sortedBookings.map((booking, index) => {
                  const isExpired = booking.status === BookingStatus.EXPIRED
                  const profileData = booking.lawyer || booking.barrister
                  const accountType = booking.lawyer
                    ? AccountType.LAWYER
                    : AccountType.BARRISTER

                  return (
                    <div
                      key={booking.id}
                      className={`${
                        isExpired ? 'opacity-60' : ''
                      } w-full  max-w-md mb-6 md:mb-6`}
                    >
                      {accountType !== AccountType.LAWYER ? (
                        <LawyerProfileCard
                          mode={CardMode.APPOINTMENT}
                          onReviewBooking={() => handleCardClick(booking)}
                          data={profileData}
                          bookingStatus={
                            <div
                              className={`text-sm px-2.5 items-center flex h-7 text-white rounded-lg font-bold ml-2 ${getStatusBackgroundColor(
                                booking.status,
                              )}`}
                            >
                              <span>{getStatus(booking.status)}</span>
                            </div>
                          }
                        />
                      ) : (
                        <BarristerProfileCard
                          mode={CardMode.APPOINTMENT}
                          onReviewBooking={() => handleCardClick(booking)}
                          data={booking.barrister as BarristerProfile}
                          bookingStatus={
                            <div
                              className={`text-sm px-2.5 items-center flex h-7 text-white rounded-lg font-bold ml-2 ${getStatusBackgroundColor(
                                booking.status,
                              )}`}
                            >
                              <span>{getStatus(booking.status)}</span>
                            </div>
                          }
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          {selectedBooking && (
            <div className='md:h-1/2 lg:h-1/3 md:w-1/2 lg:w-1/3 fixed inset-x-0 bottom-0'>
              <AppointmentDetail
                appointment={selectedBooking}
                theme={bookingTheme}
                handleClose={() => setIsVisible(false)}
                isVisible={isVisible}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { Appointments, dummyBookings }
