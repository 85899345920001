import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const UploadModal = ({ isOpen, onClose, onUpload }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        onUpload(file)
        onClose() // Automatically close the modal after file upload
      }
    },
    [onUpload, onClose],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
      <div className='bg-white w-[400px] rounded-[10px] border border-[#D8D8DF] overflow-hidden'>
        <div className='bg-[#D3D3FF] p-4 flex justify-between items-center rounded-t-[8px]'>
          <h2 className='text-lg font-semibold'>Upload your photo ID</h2>
          <button
            onClick={onClose}
            className='text-gray-600 hover:text-gray-800'
          >
            ×
          </button>
        </div>
        <div className='p-6'>
          <div
            {...getRootProps()}
            className='border-2 border-dashed border-[#A0ABBE] rounded-lg p-8 text-center cursor-pointer'
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className='text-gray-500'>Drop the file here ...</p>
            ) : (
              <p className='text-gray-500'>Click or drop your photo ID here</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadModal
