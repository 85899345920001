import React, { useState } from 'react'
import { Button } from '../../../../../common/components/Authentication/Button'
import SearchField from 'components/search/SearchField'
import cn from 'classnames'

const FeedbackModal = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    // onClose()
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 '>
      <form onSubmit={handleSubmit}>
        <div className='bg-white rounded-lg p-6 max-w-md w-full'>
          <h2 className='text-2xl font-bold text-[#122D5C] mb-4 text-center'>
            How helpful was this page?
          </h2>

          <div className='flex justify-center mb-4'>
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => setRating(star)}
                className={`text-4xl mx-1 focus:outline-none ${
                  star <= rating ? 'text-[#093269]' : 'text-gray-300'
                }`}
              >
                ★
              </button>
            ))}
          </div>

          <div className='flex justify-between text-sm mb-6'>
            <span className='text-[#122D5C]'>Not helpful</span>
            <span className='text-[#122D5C]'>Very helpful</span>
          </div>

          <h3 className='text-lg font-semibold text-[#1E3A5F] mb-2'>
            How was your overall experience?
          </h3>
          <CommentTextarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder='Very helpful!'
          />
          <Button
            variant='secondary'
            className={'w-[20%]  my-4  self-start'}
            onClick={onClose}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

const CommentTextarea = ({ value, onChange, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <textarea
      className={cn(
        'w-full rounded-lg p-2 mb-4 border transition-all duration-200 outline-none',
        {
          'border-[#B3EBF9] text-[#00323D]': isFocused,
          'border-[#E5E7EB]': !isFocused,
          'bg-white': !isFocused,
          'bg-[#F9F9F9]': isFocused,
          'placeholder-[#37A2BA]': isHovered,
        },
      )}
      value={value}
      onChange={onChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      placeholder={placeholder}
    ></textarea>
  )
}

export default FeedbackModal
