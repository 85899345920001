import React, { useRef, useState, useEffect } from 'react'
import { FaTimesCircle, FaRegClock, FaCalendarAlt } from 'react-icons/fa'
import moment from 'moment'
import { InputGroup } from 'common/components/Inputs/InputGroup'
import { Button } from '../../../common/components/Authentication/Button'
import UploadModal from './UploaModal'
import PhoneIcon from '../../../assets/phone_dark.svg'
import calendarIcon from '../../../../../public/images/calendar.svg'

const FileUpload = ({ label, onUpload, fileName }) => (
  <div className='flex items-center mb-2'>
    <span className='mr-2'>{label}:</span>
    <button className='text-blue-600 underline' onClick={onUpload}>
      {fileName ? (
        <span className='truncate max-w-xs inline-block'>{fileName}</span>
      ) : (
        'Click to upload'
      )}
    </button>
  </div>
)

const AppointmentDetail = ({ appointment, handleClose, theme, isVisible }) => {
  const [status, setStatus] = useState('Pending your acceptance')
  const [quote, setQuote] = useState(0)
  const [proofOfAddress, setProofOfAddress] = useState(null)
  const [photoId, setPhotoId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentUploadType, setCurrentUploadType] = useState(null)

  const handleFileUpload = (fileType) => {
    setCurrentUploadType(fileType)
    setIsModalOpen(true)
  }

  const handleActualFileUpload = (file) => {
    if (currentUploadType === 'proof of address') {
      setProofOfAddress(file)
    } else {
      setPhotoId(file)
    }
  }

  const handleAccept = () => setStatus('Accepted')
  const handleDecline = () => setStatus('Declined')
  const handleAudioCall = () => console.log('Initiating audio call')

  return (
    <div
      className={`fixed inset-x-0 bottom-0 h-full sm:h-3/4 md:h-2/3 lg:h-3/4 xl:h-4/5 2xl:h-5/6
                  w-full bg-white shadow-lg transition-transform duration-300 ease-in-out transform
                  ${isVisible ? 'translate-y-0' : 'translate-y-full'}
                  flex flex-col rounded-t-lg sm:rounded-t-xl`}
    >
      <div className='p-2 flex justify-end'>
        <FaTimesCircle
          size={24}
          onClick={handleClose}
          className='cursor-pointer'
        />
      </div>

      <div className='overflow-auto flex-grow p-4 sm:p-6'>
        <div>
          <div className='mb-6 font-["DM_Sans"] tracking-[0.4px]'>
            <div className='flex items-center mb-2'>
              <FaRegClock size={24} className='mr-3 text-[#020251]' />
              <span className='text-lg font-bold leading-6'>
                {moment(appointment.start).format('dddd Do MMMM YYYY')}
              </span>
            </div>
            <div className='flex items-center'>
              <img
                src={calendarIcon}
                alt='Phone icon'
                className='mr-2 ml-0 pl-0 w-8 h-8'
              />
              <span className='text-base font-medium leading-6'>
                {moment(appointment.start).format('HH:mm')} -{' '}
                {moment(appointment.end).format('HH:mm')}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6'>
            <div>
              <div className='mb-4 text-[#101828] text-base font-medium leading-6 tracking-[0.4px]'>
                Guest details
              </div>
              <div className='space-y-4'>
                <InputGroup
                  placeholder='First name'
                  isNew
                  isLabelVisible
                  value={appointment.guestFirstName}
                  id={'First name'}
                  label={'First name'}
                />
                <InputGroup
                  placeholder='Last name'
                  value={appointment.guestLastName}
                  isNew
                  isLabelVisible
                  id={'Last name'}
                  label={'Last name'}
                />
                <InputGroup
                  placeholder='Email address'
                  value={appointment.guestEmail}
                  isNew
                  isLabelVisible
                  id={'Email address'}
                  label={'Email address'}
                />
                <InputGroup
                  placeholder='Phone number'
                  value={appointment.guestPhone}
                  isNew
                  isLabelVisible
                  id={'Phone number'}
                  label={'Phone number'}
                />
                <InputGroup
                  placeholder='Occupation'
                  value={appointment.guestOccupation}
                  isNew
                  isLabelVisible
                  id={'Occupation'}
                  label={'Occupation'}
                />
              </div>
            </div>

            <div>
              <div className='mb-4 text-[#101828] text-base font-medium leading-6 tracking-[0.4px]'>
                Accessibility requirements
              </div>
              <InputGroup
                placeholder='Accessibility requirements'
                value={appointment.accessibilityRequirements}
                isNew
                multiLine={15}
                className='!bg-[#f1f2f5]'
                isLabelVisible
                id={'AccessibilityRequirements'}
                label={'Accessibility requirements'}
              />
            </div>

            <div>
              <div className='mb-4 text-[#101828] text-base font-medium leading-6 tracking-[0.4px]'>
                Notes
              </div>
              <InputGroup
                placeholder='Notes'
                id='Notes'
                value={appointment.notes}
                isNew
                multiLine={15}
                className='!bg-[#f1f2f5]'
                isLabelVisible
                label={'Notes'}
              />
            </div>
          </div>
          <div className='space-y-2 mb-6'>
            <FileUpload
              label='Upload proof of address'
              onUpload={() => handleFileUpload('proof of address')}
              fileName={proofOfAddress?.name}
            />
            <FileUpload
              label='Upload photo ID'
              onUpload={() => handleFileUpload('photo ID')}
              fileName={photoId?.name}
            />
          </div>

          <div className='mb-6 font-["DM_Sans"] text-base tracking-[0.4px]'>
            <p className='font-medium leading-6 mb-2'>
              Appointment Status:{' '}
              <span className='text-[#37A2BA] font-normal leading-5'>
                {status}
              </span>
            </p>
            <p className='font-medium leading-6'>
              Quote:{' '}
              <span className='font-normal leading-5'>
                £ {quote.toFixed(2)}
              </span>
            </p>
          </div>
          <div className='flex flex-col sm:flex-row justify-start sm:justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4'>
            <div className='flex space-x-2 w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[15%]'>
              <Button
                onClick={handleAccept}
                className='bg-[#4CAF50] text-white px-4 py-2 rounded-md shadow-md text-sm font-medium hover:bg-[#45a049] transition-colors duration-200 w-1/2'
              >
                Accept
              </Button>
              <Button
                onClick={handleDecline}
                className='bg-[#F44336] text-white px-4 py-2 rounded-md shadow-md text-sm font-medium hover:bg-[#d32f2f] transition-colors duration-200 w-1/2'
              >
                Decline
              </Button>
            </div>
          </div>
          <Button
            onClick={handleAudioCall}
            className='bg-[#020251] text-white flex items-center justify-center px-4 py-2 rounded-md shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)] text-sm font-medium
             w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[15%]'
          >
            Audio call
            <img src={PhoneIcon} alt='Phone icon' className='ml-2 w-4 h-4' />
          </Button>
        </div>
      </div>

      <UploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUpload={handleActualFileUpload}
      />
    </div>
  )
}
export default AppointmentDetail
