import React, { useState, useRef } from 'react'
import { Button } from '../../../../../common/components/Authentication/Button'
import cardThumbnail from '../../../assets/image_placeholder.svg'
import HeartIcon from '../../../assets/like_song.svg'
import ShareIcon from '../../../assets/share.svg'

export const dummyEpisodes = [
  {
    author: { name: 'John Doe', avatar: cardThumbnail },
    publishDate: '01/01/2024',
    thumbnail: cardThumbnail,
    duration: '1 hr, 30 min',
    title: 'Understanding Property Law',
    description:
      'An in-depth look at the fundamentals of property law and its implications.',
    tags: ['Property Law', 'Legal Basics', 'Real Estate'],
  },
  {
    author: { name: 'Jane Smith', avatar: cardThumbnail },
    publishDate: '15/01/2024',
    thumbnail: cardThumbnail,
    duration: '45 min',
    title: 'Corporate Law Essentials',
    description:
      'Exploring the key aspects of corporate law that every business owner should know.',
    tags: ['Corporate Law', 'Business', 'Legal Compliance'],
  },
  {
    author: { name: 'Jane Smith', avatar: cardThumbnail },
    publishDate: '15/01/2024',
    thumbnail: cardThumbnail,
    duration: '45 min',
    title: 'Corporate Law Essentials',
    description:
      'Exploring the key aspects of corporate law that every business owner should know.',
    tags: ['Corporate Law', 'Business', 'Legal Compliance'],
  },
]

export const PodcastSection = ({ episodes = dummyEpisodes }) => {
  return (
    <section className='mb-12 mx-2'>
      <div className='flex flex-col md:flex-row md:gap-[10%]'>
        <div className='w-full md:w-[40%] flex flex-col mb-8 md:mb-0'>
          <div>
            <h2 className='text-xl font-semibold mb-4 text-[#001A3D]'>
              Our conversations on legal life events
            </h2>
            <p className='text-[#001A3D] mb-4'>
              Unlock a wealth of legal knowledge by diving into our exclusive
              collection of blogs and articles, authored by seasoned attorneys.
              Whether you're seeking insights on the latest legal trends or
              guidance on complex issues, our platform offers you direct access
              to the expertise of top legal professionals. Start exploring now
              to stay informed and make well-informed decisions for your legal
              needs.
            </p>

            <Button
              variant='secondary'
              className='text-center mt-4 w-full md:w-[20%] shadow-[0_1px_4px_rgba(0,0,0,0.25)]'
              onClick={() => {}}
            >
              Show more
            </Button>
          </div>
        </div>

        <div className='w-full md:w-[50%] mx-auto overflow-x-auto'>
          <div className='flex space-x-4 pb-4 md:pb-0'>
            <EpisodeList episodes={episodes} />
          </div>
        </div>
      </div>
    </section>
  )
}

const EpisodeCard = ({
  author,
  publishDate,
  thumbnail,
  duration,
  title,
  description,
  tags,
}) => {
  return (
    <div className='bg-white rounded-xl shadow-lg overflow-hidden flex-shrink-0 w-full sm:w-[280px] md:w-[300px] lg:w-[320px] xl:w-[340px] 2xl:w-[350px]'>
      <div className='bg-white to-transparent p-2'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center space-x-2'>
            <div className='w-8 h-8 overflow-hidden rounded-full border-2 border-white'>
              <img
                src={author.avatar}
                alt={author.name}
                className='w-full h-full object-cover'
              />
            </div>
            <div className='text-white text-sm'>
              <p className='font-semibold text-black'>{author.name}</p>
              <p className='text-xs opacity-80 text-[#1C7D93]'>
                Published on {publishDate}
              </p>
            </div>
          </div>
          <button className='bg-white rounded-full justify-center p-2'>
            <img src={ShareIcon} alt='Share' />
          </button>
        </div>
      </div>
      <div className='aspect-w-16 aspect-h-9 bg-white overflow-hidden'>
        <img
          src={thumbnail}
          alt='Episode thumbnail'
          className='object-cover w-full h-full'
        />
      </div>
      <div className='p-4'>
        <div className='flex items-center justify-between mb-2'>
          <div className='flex items-center space-x-2'>
            <button className='text-gray-400 hover:text-gray-600'>
              <img src={HeartIcon} alt='Heart' />
            </button>
            <span className='text-sm text-gray-600'>20 plays</span>
          </div>
          <span className='text-sm text-gray-600'>{duration}</span>
        </div>
        <div className='w-full bg-gray-200 rounded-full h-1.5 mb-4'>
          <div
            className='bg-blue-600 h-1.5 rounded-full'
            style={{ width: '20%' }}
          ></div>
        </div>
        <h2 className='text-xl font-bold text-gray-800'>{title}</h2>
        <p className='mt-2 text-sm text-gray-600 line-clamp-3'>{description}</p>
        <div className='mt-4 flex flex-wrap gap-2'>
          {tags.map((tag, index) => (
            <span
              key={index}
              className='px-3 py-1 bg-blue-100 text-blue-800 text-xs font-semibold rounded-full'
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export const EpisodeList = ({ episodes }) => {
  return (
    <>
      {episodes.map((episode, index) => (
        <EpisodeCard key={index} {...episode} />
      ))}
    </>
  )
}
