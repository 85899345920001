import React from 'react'
import { ContactBar } from 'components/ContactBar'
import backgroundImg from '../../../assets/home_background_1.svg'
import { Button } from '../../../common/components/Authentication/Button'

const InfoSection: React.FC<InfoSectionProps> = () => {
  const cards = [
    {
      title: 'Is my legal need complex?',
      subtitle: 'Explore if your legal need is basic or complex',
      body: 'Introducing A.I. Lawyer, your trusted companion for navigating the complex world of law with confidence. Say goodbye to endless hours of research and uncertainty, and say hello to instant, reliable guidance at your fingertips.',
    },
    {
      title: 'How much is it going to cost?',
      subtitle: 'The different ways legal costs are estimated.',
      body: 'Legal costs have a reputation for being high and uncertain globally, there is no exception for England and Wales. We take a look at how traditional legal costs are estimated, how to ensure you can manage these costs, and the things to look for when seeking a cost quotation.',
    },
  ]

  return (
    <div className='w-full mb-[14vh] sm:mb-[18vh] md:mb-[22vh] lg:mb-[24vh] xl:mb-[26vh] 2xl:mb-[27.78vh] relative overflow-hidden'>
      <div className='px-6 md:px-12 mx-auto w-full flex flex-col items-center'>
        <div className='w-full flex flex-col items-center justify-center text-center mt-[2%] mb-[20vh]'>
          <p className='text-lue-blue-700 text-base font-medium leading-6 tracking-[0.4px]'>
            Need help with your search?
          </p>
          <ContactBar className='mt-6 inline-flex justify-center' />
        </div>
      </div>

      <div
        className='absolute -left-[2%] -right-[2%]   2xl:h-[100%] bottom-[0%] h-[75%] bg-no-repeat bg-cover bg-bottom'
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: 'fit',
        }}
      />

      <div className='relative px-6 md:px-12 mx-auto'>
        <div className='w-full flex flex-col lg:flex-row justify-center place-content-between items-center space-y-12 lg:space-y-0 lg:space-x-16 xl:space-x-24 2xl:space-x-28'>
          {cards.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default InfoSection

interface CardProps {
  title: string
  subtitle: string
  body: string
}

const Card: React.FC<CardProps> = ({ title, subtitle, body }) => (
  <div className='bg-[#F5F7FB] bg-gradient-to-b from-[#FAFCFE] to-[#E9EEF6] backdrop-blur-[10px] rounded-lg p-8 shadow-lg bg-transparent-30 w-full sm:h-[65vh] md:h-[35vh] lg:h-[35vh] xl:h-[45vh] 2xl:h-[30vh] lg:w-[40%] xl:w-[40%] 2xl:w-[40%] flex flex-col justify-between'>
    <div>
      <h2 className='text-3xl font-bold text-[#1E3A5F] mb-6'>{title}</h2>
      <h3 className='text-xl font-semibold text-[#1E3A5F] mb-4'>{subtitle}</h3>
      <p className='text-[#405985]'>{body}</p>
    </div>
    <Button
      variant='secondary'
      className={'w-[10%]   py-2 px-4  mt-6 self-start'}
    >
      Read
    </Button>
  </div>
)

interface InfoSectionProps {}
