import React, { useState, ChangeEvent, FormEvent, ReactNode } from 'react'
import cn from 'classnames'

interface SearchFieldProps {
  placeholder: string
  onSearch: (term: string) => void
  icon?: ReactNode
  error?: boolean
  disabled?: boolean
}

const SearchField: React.FC<SearchFieldProps> = ({
  placeholder,
  onSearch,
  icon,
  error = false,
  disabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!disabled) {
      onSearch(searchTerm)
    }
  }

  return (
    <form onSubmit={handleSubmit} className='relative'>
      <input
        type='text'
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        disabled={disabled}
        className={cn(
          'w-full p-3 pr-10 rounded-[4px] border-2 transition-all duration-200 outline-none',
          {
            'border-[#B3EBF9] text-[#00323D] bg-white':
              isFocused && !error && !disabled,
            'border-[#CE2815] text-[#00323D]': error && !disabled,
            'border-[#E5E7EB]': !isFocused && !error && !disabled,
            'bg-white': !isFocused && !disabled,
            'bg-[#F9F9F9]': isFocused && !disabled,
            'bg-[#F4F4F4] text-[#A0ABBE] cursor-not-allowed': disabled,
            'placeholder-[#37A2BA]': isHovered && !disabled,
          },
        )}
      />
      {icon && !disabled && (
        <button
          type='submit'
          className='absolute right-3 top-1/2 transform -translate-y-1/2 bg-transparent border-none'
        >
          {icon}
        </button>
      )}
    </form>
  )
}

export default SearchField
