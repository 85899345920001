import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavbarLogo } from 'common/components/Nav/NavbarLogo'
import ProfileIcon from '../../../cwp/src/assets/profile_avatar.svg'
import Constants from '../../../common/utils/constants'
import questionIcon from '../assets/question.svg'
import menuIcon from '../assets/sidebar_menu_icon.svg'
import closeIcon from '../assets/close_icon.svg'
import useProfile from '../../../common/providers/useProfile'
import { useAuth } from '../common/providers/useAuth'

const EXCLUDED_ROUTES = [
  /legal-documents\/[^/]+\/download/,
  ...Constants.authRoutes,
]

const NAV_ITEMS = [
  'Our App',
  'Discover Law',
  'Legal help',
  'Pricing',
  'For Lawyers',
  'Who We Are',
  'Contact us',
]

const AppHeader = () => {
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const dropdownRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location
  const { profile } = useProfile()
  const { logout } = useAuth()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (
    EXCLUDED_ROUTES.some((route) =>
      route instanceof RegExp ? route.test(pathname) : route === pathname,
    )
  ) {
    return null
  }

  const handleClick = (item) => {
    setActiveDropdown(null)
    setIsMobileMenuOpen(false)

    switch (item) {
      case 'Our App':
        navigate('/')
        break
      case 'Discover Law':
        navigate('/discover-law')
        break
      case 'Legal help':
        navigate('/legal-fields')
        break
      case 'Contact us':
        setActiveDropdown(activeDropdown === item ? null : item)

        break
      case 'Pricing':
        navigate('/pricing')
        break
      case 'For Lawyers':
        navigate('/for-lawyers')
        break
      case 'Who We Are':
        navigate('/who-we-are')
        break

      default:
        break
    }
  }

  return (
    <header className="bg-white shadow-md font-['DM_Sans']">
      <div className='container mx-auto'>
        <div className='flex justify-between items-center py-4 px-2'>
          <NavbarLogo />
          <div className='hidden lg:flex items-center justify-center flex-grow'>
            <nav className='flex space-x-4'>
              {NAV_ITEMS.map((item) => (
                <button
                  key={item}
                  className='text-[#586885] hover:text-blue-600 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-lg 2xl:text-xl font-bold leading-6 tracking-[0.4px]'
                  onClick={() => handleClick(item)}
                >
                  {item}
                </button>
              ))}
            </nav>
          </div>
          <div className='hidden lg:flex items-center space-x-4 '>
            <ContactSupportButton />
            {profile ? (
              <div className='flex items-center space-x-2'>
                <span className='text-[#586885] text-sm sm:text-sm md:text-sm lg:text-sm xl:text-lg 2xl:text-xl font-bold leading-6 tracking-[0.4px]'>
                  {profile.first_name}
                </span>
                <button
                  onClick={() => logout({ id: profile?.id })}
                  className='text-[#586885] hover:text-blue-600 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-lg 2xl:text-xl font-bold leading-6 tracking-[0.4px]'
                >
                  Logout
                </button>
              </div>
            ) : (
              <button
                onClick={() => navigate('/login')}
                className='text-[#586885] hover:text-blue-600 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-lg 2xl:text-xl font-bold leading-6 tracking-[0.4px] flex items-center'
              >
                Login
                <img
                  className='ml-2 h-5 w-5 sm:h-5 sm:w-5 md:h-5 md:w-5 lg:h-5 lg:w-5 xl:h-6 xl:w-6 2xl:h-7 2xl:w-7'
                  src={ProfileIcon}
                  alt='Profile'
                />
              </button>
            )}
          </div>
          <div className='lg:hidden flex items-center space-x-4'>
            <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <img src={menuIcon} alt='Menu' className='w-6 h-6' />
            </button>
          </div>
        </div>
      </div>
      {activeDropdown && (
        <div ref={dropdownRef}>
          <DropdownMenu activeItem={activeDropdown} />
        </div>
      )}
      {isMobileMenuOpen && (
        <MobileMenu onClose={() => setIsMobileMenuOpen(false)} />
      )}
    </header>
  )
}

const ContactSupportButton = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/contact-us')
  }

  return (
    <button
      onClick={handleClick}
      className='flex items-center text-[#586885] hover:text-blue-600 text-xs sm:text-sm md:text-sm xl:text-lg 2xl:text-xl font-bold leading-6 tracking-[0.4px]'
    >
      <img
        src={questionIcon}
        alt='Question icon'
        className='w-4 sm:w-4 md:w-4 lg:w-4 xl:w-5 2xl:w-5 mr-1.5'
      />
      <div className='hidden md:block text-sm sm:text-sm md:text-sm lg:text-sm xl:text-lg 2xl:text-xl'>
        Contact support
      </div>
    </button>
  )
}

const DropdownMenu = ({ activeItem }) => {
  if (activeItem === 'Discover Law') {
    const legalCategories = [
      'Canine',
      'Charity',
      'Civil',
      'Commercial',
      'Competition',
      'Contract',
      'Corporate',
      'Criminal',
      'Data Protection',
      'Employment',
      'Environmental',
      'Family',
      'General',
      'Health',
      'Intellectual Property',
      'Planning, Property and Land Law',
      'Shipping',
      'Tax',
      'Wills and Probate',
    ]

    return (
      <div className='w-full bg-white border-t border-gray-200'>
        <div className='container mx-auto px-4 py-4'>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4'>
            {legalCategories.map((category) => (
              <div
                key={category}
                className='text-[#586885] hover:text-[#A0ABBE] cursor-pointer text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl font-bold leading-6 tracking-[0.4px]'
              >
                {category}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  if (activeItem === 'Legal help') {
    const legalHelpItems = [
      { title: 'Find a Lawyer', items: ['Page 1', 'Page 2', 'Page 3'] },
      { title: 'Create a Legal Doc', items: ['Page 1', 'Page 2', 'Page 3'] },
      {
        title: 'Resources',
        items: [
          'Types of Lawyer',
          'Types of Legal Services',
          'Types of Payment Options',
          'Choosing a lawyer',
        ],
      },
      { title: 'Diagnose my issue', items: [] },
      { title: 'Answer my question', items: [] },
    ]

    return (
      <div className='w-full bg-white border-t border-gray-200'>
        <div className='container mx-auto px-4 py-4'>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4'>
            {legalHelpItems.map((section) => (
              <div key={section.title}>
                <h3 className='text-[#A0ABBE] font-bold mb-2 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl leading-6 tracking-[0.4px]'>
                  {section.title}
                </h3>
                {section.items.map((item) => (
                  <div
                    key={item}
                    className='text-[#586885] hover:text-[#A0ABBE] cursor-pointer mb-1 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl font-semibold leading-6 tracking-[0.4px]'
                  >
                    {item}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  if (activeItem === 'Contact us') {
    return (
      <div className='w-full bg-white border-t border-gray-200'>
        <div className='container mx-auto px-4 py-4'>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
            <div>
              <h3 className='text-[#A0ABBE] font-bold mb-2 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl leading-6 tracking-[0.4px]'>
                Contact methods
              </h3>
              <div className='text-[#586885] mb-1 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl font-semibold leading-6 tracking-[0.4px]'>
                Message us
              </div>
              <div className='text-[#586885] mb-1 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl font-semibold leading-6 tracking-[0.4px]'>
                Email: email@gmail
              </div>
              <div className='text-[#586885] mb-1 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl font-semibold leading-6 tracking-[0.4px]'>
                Call us: 020 3918 5630
              </div>
            </div>
            <div>
              <h3 className='text-[#A0ABBE] font-bold mb-2 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl leading-6 tracking-[0.4px]'>
                Newsletter
              </h3>
              <div className='text-[#586885] mb-1 text-xs sm:text-sm md:text-base xl:text-lg 2xl:text-xl font-semibold leading-6 tracking-[0.4px]'>
                Receive weekly email
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

const MobileMenu = ({ onClose }) => {
  const [expandedItems, setExpandedItems] = useState([])

  const toggleExpand = (item) => {
    setExpandedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    )
  }

  return (
    <div className='fixed inset-0 bg-white z-50 overflow-y-auto'>
      <div className='flex justify-between items-center p-4 border-b'>
        <div className='flex items-center space-x-2'>
          <span className='text-lg font-semibold'>Menu</span>
        </div>
        <button onClick={onClose}>
          <img src={closeIcon} alt='Close' className='w-6 h-6' />
        </button>
      </div>
      <nav className='p-4'>
        <div className='space-y-4'>
          {NAV_ITEMS.map((item) => (
            <MenuItem key={item} text={item} />
          ))}
        </div>
        <div className='mt-8'>
          <button className='text-blue-600 font-semibold'>Sign in</button>
        </div>
      </nav>
    </div>
  )
}

const MenuItem = ({ text }) => (
  <div className='text-[#586885] text-lg font-semibold'>{text}</div>
)

export default AppHeader
