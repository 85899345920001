import React from 'react'
import discoverPageBackground1 from '../../../assets/discover-page-background-1.svg'
import { useFirebaseCloudStorage } from '../../../common/firebase/useFirebaseCloudStorage'

export const DiscoverLegalPerspective = () => {
  const { getImages, getAudios, loading, error } =
    useFirebaseCloudStorage('images')
  const images = getImages()

  return (
    <div className='relative py-[4%] pb-6 mb-8  mt-12 '>
      <img
        src={discoverPageBackground1}
        alt=''
        className='absolute w-full z-[-999]'
      />
      <section className='bg-transparent rounded-lg relative z-10'>
        <h2 className='text-xl sm:text-2xl md:text-3xl font-semibold text-[#101828] mb-[8%] ml-[4%] transform -translate-y-[50%]'>
          You might want to discover the legal perspective
        </h2>

        <div className='flex flex-col md:flex-row gap-[10%] ml-[4%]'>
          <div className='flex-[0_0_40%]'>
            <p className='text-sm sm:text-lg font-semibold text-[#001A3D] mb-4'>
              Explore our expert-authored blogs and articles by top legal
              professionals to gain valuable insights into the legal services
            </p>
            <p className='text-sm text-[#001A3D]'>
              Unlock a wealth of legal knowledge by diving into our exclusive
              collection of blogs and articles, authored by seasoned attorneys.
              Whether you're seeking insights on the latest legal trends or
              guidance on complex issues, our platform offers you direct access
              to the expertise of top legal professionals. Start exploring now
              to stay informed and make well-informed decisions for your legal
              needs.
            </p>
          </div>

          <div className='flex-[0_0_45%]'>
            <div className='w-full aspect-[15/6] mb-2'>
              <div className='bg-[#101828] w-full h-full rounded-lg flex items-center justify-center text-white'>
                <img
                  src={images[0]}
                  alt=''
                  className='h-full w-auto object-contain'
                />
              </div>
            </div>
            <p className='text-sm font-medium pt-2'>
              Changes to commercial property evictions feat ABC Law
            </p>
            <span className='text-xs text-gray-500'>25th July, 2024</span>
          </div>
        </div>
      </section>
    </div>
  )
}
