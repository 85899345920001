import { BaseContainer } from 'components/BaseContainer'
import SearchBarV2 from './searchBarV2'

export const HeroSectionV2 = () => (
  <div className='py-16 bg-[#EBF3FF80] pb-[10vh] sm:pb-[10vh] md:pb-[12vh] lg:pb-[14vh] xl:pb-[15vh] 2xl:pb-[15.89vh]'>
    <BaseContainer
      size='lg'
      className='flex flex-col items-center justify-center'
    >
      <h1 className='text-[#16253B] text-5xl xl:text-6xl font-bold text-center leading-tight mb-8'>
        Legal Solved, <span className='text-brand-primary'>Fast</span>
      </h1>

      <div className='w-full max-w-2xl relative'>
        <SearchBarV2 initialValue='' debounceTime={500} />
        <p className='text-[#16253B] text-sm mt-4 underline absolute right-0 top-full'>
          Learn about this service
        </p>
      </div>
    </BaseContainer>
  </div>
)
