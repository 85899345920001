import React, { useState } from 'react'
import contactBG from '../../assets/contact-bg.svg'
import Project from '../../common/project'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { InputGroup } from '../../common/components/Inputs/InputGroup'
import { Button } from '../../common/components/Authentication/Button'
import { CheckboxGroup } from '../../common/components/Inputs/CheckboxGroup'
import Constants from '../../common/utils/constants'

// Validation schema
const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().optional(),
  email: yup
    .string()
    .email('Email must be valid')
    .required('Email address is required'),
  phone: yup.string().required('Phone number is required'),
  message: yup.string().required('Message is required'),
  terms: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and privacy policy')
    .required('You must accept the terms and privacy policy'),
})

interface ContactPageProps {}

const ContactPage = ({}: ContactPageProps) => {
  const { handleBlur, handleSubmit, handleChange, errors, setTouched, values } =
    useFormik({
      validateOnBlur: false,
      validateOnChange: false,
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        terms: false,
      },
      validationSchema: schema,
      onSubmit: (values) => {
        const mailtoLink = `mailto:${
          Constants.supportEmail
        }?subject=Contact%20Request&body=First%20Name:%20${encodeURIComponent(
          values.firstName,
        )}%0D%0ALast%20Name:%20${encodeURIComponent(
          values.lastName,
        )}%0D%0AEmail:%20${encodeURIComponent(
          values.email,
        )}%0D%0APhone:%20${encodeURIComponent(
          values.phone,
        )}%0D%0AMessage:%20${encodeURIComponent(values.message)}`
        window.location.href = mailtoLink
      },
    })

  const onSubmit = (e: any) => {
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  return (
    <div className='flex justify-center items-center relative'>
      <img
        src={contactBG}
        alt='Contact background'
        className='absolute inset-0 w-full h-full object-cover'
      />
      <div
        className='mt-[8%] bg-[#D1D3D7]  bg-opacity-10 backdrop-filter backdrop-blur-lg border border-white
       shadow-[0 1px 26px 0 rgba(187, 199, 215, 0.5]
       border-opacity-40 rounded-xl px-[4%] py-[4%] md:px-[4%] md:py-[4%] xl:px-[8%] xl:py-[6%] 2xl:px-[8%] 2xl:py-[6%] min-w-[60%] '
      >
        <h1 className=' mb-4 text-4xl font-normal leading-normal '>
          Contact us
        </h1>
        <form onSubmit={onSubmit}>
          <div className='grid grid-cols-2 gap-4 mb-4'>
            <InputGroup
              id='firstName'
              label='First Name'
              name='firstName'
              theme='grey'
              isLabelVisible
              isNew
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={errors.firstName}
              placeholder='First name'
              className='w-full'
            />
            <InputGroup
              id='lastName'
              label='Last Name'
              name='lastName'
              theme='grey'
              isLabelVisible
              isNew
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={errors.lastName}
              placeholder='Last name'
              className='w-full'
            />
            <InputGroup
              id='email'
              label='Email'
              name='email'
              theme='grey'
              isLabelVisible
              isNew
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              placeholder='Email address'
              className='w-full'
            />
            <InputGroup
              id='phone'
              label='Phone'
              name='phone'
              theme='grey'
              isLabelVisible
              isNew
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone}
              placeholder='Phone number'
              className='w-full'
            />
          </div>
          <InputGroup
            id='message'
            label='Message'
            name='message'
            theme='grey'
            isLabelVisible
            isNew
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            error={errors.message}
            placeholder='Message'
            className='w-full h-32'
          />
          <div className='flex justify-start mb-4 mt-6'>
            <CheckboxGroup
              id='terms'
              label={
                <span className='text-base text-[#6E7785]'>
                  I agree with the
                  <a
                    href={new URL('/terms-of-use-consumer', Project.cwp).href}
                    className='!text-blue inline-block px-1'
                  >
                    Terms
                  </a>
                  and
                  <a
                    href={new URL('/privacy-policy-web', Project.cwp).href}
                    className='!text-blue inline-block px-1'
                  >
                    Privacy policy
                  </a>
                </span>
              }
              name='terms'
              onBlur={handleBlur}
              error={errors.terms}
              checked={values.terms}
              onChange={handleChange}
            />
          </div>

          <div className='flex justify-end'>
            <Button className='w-[15%]' type='submit'>
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactPage
