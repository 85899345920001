import React, { useState, FormEvent, ChangeEvent } from 'react'

interface SearchBarProps {
  placeholder?: string
  className?: string
  initialValue?: string
  debounceTime?: number
  rows?: number
}

const SearchBarV2: React.FC<SearchBarProps> = ({
  placeholder = 'Type what you are searching for...',
  className = '',
  initialValue = '',
  debounceTime = 300,
  rows = 5,
}) => {
  const [searchTerm, setSearchTerm] = useState(initialValue)

  const handleSearch = (term: string) => {
    console.log(term)
  }

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout
    return (...args: any[]) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  const debouncedSearch = React.useCallback(
    debounce((term: string) => handleSearch(term), debounceTime),
    [handleSearch, debounceTime],
  )

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSearch(searchTerm)
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newTerm = e.target.value
    setSearchTerm(newTerm)
    debouncedSearch(newTerm)
  }

  return (
    <form onSubmit={handleSubmit} className={`relative ${className}`}>
      <textarea
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        rows={rows}
        className='w-full py-3 px-6 rounded-[20px] border-2 border-[#020251] bg-[#EBF3FF] resize-none
         focus:outline-none focus:ring-2 focus:ring-[#020251] focus:border-transparent
         shadow-[-0.2px_-0.2px_30px_0px_rgba(190,190,223,0.5),0.2px_0.2px_30px_0px_rgba(190,190,223,0.5)]
                  '
      />
      <button
        type='submit'
        className='absolute right-4 bottom-4 transform'
        aria-label='Search'
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z'
            stroke='#020251'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </form>
  )
}

export default SearchBarV2
